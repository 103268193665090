import React from 'react'

export default class PageMenu extends React.Component {


    render() {
        let pageMenuClass = this.props.isOpen ? 'page-menu open' : 'page-menu closed';

        return (
            <div className={pageMenuClass}>

            <ul>
                {this.props.children}
            </ul>

        </div>);
  }
}
