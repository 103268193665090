import React from 'react'

export default class NavToggle extends React.Component {
  constructor() {
    super();
    this.menuItemRef = React.createRef();
  }
  render() {
    let className = "menu-button";
    this.props.menuState ? className+=" open" : className+=" closed";
    return (
    <li className="nav-item nav-toggle" onClick={()=> {this.props.handleClick()}} >
      <div className={className} ref={this.menuItemRef} style={{backgroundColor: this.props.color}}>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
      </div>
    </li>);
  }
}
