
export class SidePanelService{
    constructor(){
        this.activeMenu = null;
        this.monitoring = [];
    }

    monitor(callbacks) {
        this.monitoring.push(callbacks);
    }
    setActiveMenu(menu) {
        this.activeMenu = menu;
    }

    setSidePanel(component, style, label) {
        if (!this.activeMenu) {
            return;
        }
        this.activeMenu.openSidePanel(component, style, label);
        this.monitoring.forEach(element => {
    
            if (typeof element.in === 'function') {
                element.in();
            }
        });
    }

    closeSidePanel() {
        this.activeMenu.closeSidePanel();

        this.monitoring.forEach(element => {
            if (typeof element.out === 'function') {
                element.out();
            }
        });
    }
}



export default new SidePanelService();
