import React from 'react';
import ReactMarkdown from "react-markdown";
import SectionHeading from './section-heading';

export default class TextWithImage extends React.Component {
    constructor(props) {
        super(props)
        this.contentRef = React.createRef();
        this.imageRef = React.createRef();

    }

    componentDidMount() {
        // let from = this.props.content.orientation === 'left' ? ['-100%', '0%'] : ['100%', '0%'];
        // anime({
        //     targets: this.contentRef.current,
        //     duration: 600,
        //     delay: 1000,
        //     opacity: [0, 1],
        //     translateY: ['200px', '0px'],
        //     easing: 'easeInOutQuad'
        // });
        // anime({
        //     targets: this.imageRef.current,
        //     duration: 600,
        //     delay: 1000,
        //     opacity: [0, 1],
        //     translateX: from,
        //     easing: 'easeInOutQuad'
        // });
    }

    createMarkup() {
        return <ReactMarkdown source={this.props.content.textContent} />
    }

    render() {
        let className = `indexable text-with-image ${this.props.content.orientation || 'top'}`;
        let image = this.props.content.imageContent?.formats?.large || this.props.content.imageContent?.formats?.medium || this.props.content.imageContent
        let imageRatio = image?.height / image?.width;
        let imageStyle = {
            order: this.props.content.orientation === 'right' ? 1 : 0,
            backgroundImage: `url(${image?.url}`,
            height: this.props.content.orientation === 'top' ? `calc(60vw * ${imageRatio})` : `calc(100vw * ${imageRatio})`,
            backgroundSize: this.props.content.orientation === 'top' ? 'contain' : 'cover',
            zIndex: 1
        }
        let textStyle = {};
        let top = this.props.content.imageContent ? '450px' : '100px';
        if (this.props.content.textColor) {
            textStyle.color = this.props.content.color;

        }
        return (
            <section className={className} id={'section-' + this.props.content._id} >
                <div className="text-image-intro">
                    {(this.props.content.preTitle || this.props.content.sectionTitle) && <SectionHeading>
                        <i>{this.props.content.preTitle} </i>
                        <h1>{this.props.content.sectionTitle?.toUpperCase()}</h1>
                    </SectionHeading>}
                    {this.props.content.introQuote && <blockquote><p>{this.props.content.introQuote}</p></blockquote>}
                </div>

                <div className="text-image-body">
                    {this.props.content.backgroundColor && <div className="rich-text-background" style={{ backgroundColor: this.props.content.backgroundColor, top }} />}

                    {image && <div ref={this.imageRef} style={imageStyle} className="image-container" />}

                    <div className="text-image-content">
                        <div className="rich-text" style={textStyle} ref={this.contentRef}>
                            {this.createMarkup()}
                        </div>
                    </div>
                </div>



            </section>);
    }
}
