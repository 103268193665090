import React from 'react';
import ImageThumbs from './image-thumbs';
import ImageLightbox from './image-lightbox';
export default class ImageGallery extends React.Component {
  constructor() {
      super();
      this.state = { loading: true, showingImage: null };
      this.thumbnailClick = this.thumbnailClick.bind(this);
      this.closeLightbox = this.closeLightbox.bind(this);
  }

    componentDidMount(){
    }

    thumbnailClick(image) {
        if (!this.props.useLightbox) {
            return;
        }

        this.setState({ showingImage: image });
    }

    closeLightbox() {
        this.setState({ showingImage: null });
    }

    render() {
        let thumbStyle = {
            cursor: this.props.useLightbox ? 'pointer' : 'normal'
        }

        return (<section className="image-gallery">
            {this.props.useLightbox && <ImageLightbox close={this.closeLightbox} thumbClick={this.thumbnailClick} showImage={this.state.showingImage} images={this.props.images} />}
            <ImageThumbs thumbStyle={thumbStyle} images={this.props.images} onClick={this.thumbnailClick} />    
        </section>
        );
            }
            }
