import React from 'react'
export default class Overlay extends React.Component {

    render() {
        let className = 'overlay';
        if (this.props.className !== undefined) {
            className += ' ' + this.props.className;
        }
        return (<div className={className} onClick={this.props.onClick}>
      {this.props.children}
    </div>);
  }
}
