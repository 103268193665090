import React from 'react'
import ReactMarkdown from 'react-markdown';
export default class QuoteSmall extends React.Component {
  constructor() {
    super();
    this.contentRef = React.createRef();

  }

  componentDidMount() {
  }

  render() {
    return (
      <section className="small-quote-section">
        <blockquote>
          <ReactMarkdown source={this.props.textContent} />
        </blockquote>

      </section>
    );
  }
}
