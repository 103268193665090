import React from 'react';

export default class ImageMasked extends React.Component {

    render() {
        const style = {
            WebkitMaskImage: 'url(' + this.props.mask + ')',
            maskImage: 'url(' + this.props.mask + ')',
            WebkitMaskRepeat: 'no-repeat',
            maskRepeat: 'no-repeat',
            WebkitMaskSize: 'contain',
            maskSize: 'contain',
            backgroundImage: 'url(' + this.props.src + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        
        };
            return (
                <div {...this.props} style={style} />
            );
            
    }
}
