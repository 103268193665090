export const urlPrefix = process.env.NODE_ENV === 'development' ? '' : 'https://beheard.no/api'

export async function getArticles(endpoint) {
    let response = await fetch(`${urlPrefix}/${endpoint}`);
    let articles = await response.json();
    if (articles.error !== undefined) {
        return [];
    }
    return articles;
}

export function extractSearch(searchString) {
    let result = {};
    searchString.split('&').forEach(string => {
        let pairs = string.replace('?', '').split('=');
        result[pairs[0]] = pairs[1];
    });

    return result;
}

export function createCategories(articles) {
    let categories = articles.reduce((cats, article) => {
        article.categories && article.categories.forEach(cat => {
            if (!cats.some(item => cat._id === item._id)) {
                cats.push(cat);
            }
        });

        return cats;

    }, [])
    return categories;
}

