import React from 'react'
import anime from 'animejs/lib/anime.es.js';
import mediaQuery from '../helpers/media-query';

export default class SidePanel extends React.Component {

    componentDidMount() {
        let settings = {
            targets: '.side-panel',
            easing: 'easeOutQuad',
            duration: 600,
        }
        if (!mediaQuery.isMobile()) {
            settings.padding = '0 5rem';
            settings.width = ['0px', (this.props.style.width || 400) + 'px'];

        } else {
            settings['max-height'] = ['0vh', '100vh'];

        }
        anime(settings);
        anime({
            targets: '.sub-menu-item',
            easing: 'easeOutQuad',
            delay: 600,
            opacity: [0,1],
            duration: 300,
        })

    }

    componentDidUpdate(prevProps) {
   
    }


    render() {
        return (<div className="side-panel-backdrop" onClick={() => { this.props.closeMenu() }}>
            <div className="side-panel" style={this.props.style} onClick={(e) => { e.stopPropagation() }}>
                {this.props.children}
            </div>
        </div>);
    }
}
