export function scrollTo(element, duration, offset){
    let el = document.querySelector(element);
    if (offset === undefined) {
        offset = 0;
    }
        if (el === null) {
            return;
        }
        var startingY = window.pageYOffset
    var elementY = window.pageYOffset + el.getBoundingClientRect().top

    var targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight - offset : elementY - offset
        var diff = targetY - startingY
        // Easing function: easeInOutCubic
        // From: https://gist.github.com/gre/1650294
        var easing = function (t) { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1 }
        var start

        if (!diff) return

        var startingX = window.pageXOffset
        var elementX = window.pageXOffset + el.getBoundingClientRect().left
        var targetX = document.body.scrollWidth - elementX < window.innerWidth ? document.body.scrollWidth - offset - window.innerWidth : elementX - offset
        var diffX = targetX - startingX

        // Bootstrap our animation - it will get called right before next frame shall be rendered.
        window.requestAnimationFrame(function step(timestamp) {
            if (!start) start = timestamp
            // Elapsed miliseconds since start of scrolling.
            var time = timestamp - start
            // Get percent of completion in range [0, 1].
            var percent = Math.min(time / duration, 1)
            // Apply the easing.
            // It can cause bad-looking slow frames in browser performance tool, so be careful.
            percent = easing(percent)
            window.scrollTo(startingX + diffX * percent, startingY + diff * percent)

            // Proceed with animation as long as we wanted it to.
            if (time < duration) {
                window.requestAnimationFrame(step)
            }
        })
}

export function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export function toUpperFirst(string) {
    return string.slice(0, 1).toUpperCase() + string.slice(1, string.length);
}


export default{
    scrollTo,
    shuffle,
    toUpperFirst
}

