import React from 'react'
import NavItem from './nav-item';
import { ReactComponent as SearchIcon } from '../images/search-icon.svg';
import { ReactComponent as CalenderIcon } from '../images/calender-icon.svg';
import { ReactComponent as CommaIcon } from '../images/comma-icon.svg';
import { ReactComponent as ExhIcon } from '../images/exh.svg';
import { ReactComponent as FilmSolid } from '../images/iconmonstr-video-9.svg';
import SidePanel from './side-panel';
import anime from 'animejs/lib/anime.es.js';
import CalenderPanel from '../components/calender-panel';
import ScrollSpy from '../helpers/scroll-spy';
import StoriesPanel from '../components/stories-panel';
import NavToggle from './nav-toggle';
import styles from '../helpers/styles';
import MainMenu from './main-menu';
import SearchPanel from '../components/search-panel';
import mediaQuery from '../helpers/media-query';
import VideoStoriesPanel from '../components/video-stories-panel';
import SidePanelService from './side-panel-service';
import ExhibitionsPanel from '../components/exhibitions-panel';


export default class NavMenu extends React.Component {
    constructor() {
        super();
        this.state = {
            showText: false,
            sidePanel: null,
            sidePanelId: null,
            tempMenuColor: styles.yellowBackground
        }

        //this.mainMenu = <MainMenu closeMenu={this.closeSidePanel} />;
        //this.calenderSidePanel = HocEvents(CalenderPanel, EventCard, (events) => { return events });
        //this.storiesSidePanel = <StoriesPanel />;
        this.menuButtonClick = this.menuButtonClick.bind(this);
        this.openSidePanel = this.openSidePanel.bind(this);
        this.closeSidePanel = this.closeSidePanel.bind(this);
        this.calenderButtonClick = this.calenderButtonClick.bind(this);
        this.storiesClick = this.storiesClick.bind(this);
        this.videosClick = this.videosClick.bind(this);
        this.exhibitionsClick = this.exhibitionsClick.bind(this);
        this.searchButtonClick = this.searchButtonClick.bind(this);
        SidePanelService.setActiveMenu(this);
    }

    componentDidMount() {

    }

    storiesClick() {

        if (this.state.sidePanelId === 'stories') {
            return this.closeSidePanel();
        }

        let style = {
            backgroundColor: styles.lightBrown,
        }

        this.openSidePanel(<StoriesPanel />, style, 'stories');
    }

    videosClick() {

        if (this.state.sidePanelId === 'video-stories') {
            return this.closeSidePanel();
        }

        let style = {
            backgroundColor: styles.pinkBackground,
        }

        this.openSidePanel(<VideoStoriesPanel />, style, 'video-stories');
    }
    exhibitionsClick() {

        if (this.state.sidePanelId === 'exhibitions') {
            return this.closeSidePanel();
        }

        let style = {
            backgroundColor: styles.greenBackground,
        }

        this.openSidePanel(<ExhibitionsPanel />, style, 'exhibitions');
    }
    calenderButtonClick() {
        if (this.state.sidePanelId === 'calender') {
            return this.closeSidePanel();
        }

        let style = {
            backgroundColor: styles.greenBackground,
        }
        this.openSidePanel(<CalenderPanel />, style, 'calender');
    }

    searchButtonClick() {
        if (this.state.sidePanelId === 'search') {
            return this.closeSidePanel();
        }

        let style = {
            backgroundColor: styles.pinkBackground,
        }
        this.openSidePanel(<SearchPanel searchResultClick={this.closeSidePanel} />, style, 'search');
    }

    menuButtonClick() {
        if (this.state.sidePanel !== null) {
            SidePanelService.closeSidePanel();
            this.props.setMenuColor(this.state.tempMenuColor);

        } else {
            this.setState({ tempMenuColor: this.props.color });
            let style = {
                backgroundColor: styles.frantzYellow,
            }
            SidePanelService.setSidePanel(<MainMenu searchClick={this.searchButtonClick} closeMenu={this.closeSidePanel} />, style, 'menu');

            this.props.setMenuColor(styles.frantzYellow);
        }
    }

    openSidePanel(sidePanelContent, style, id) {
        ScrollSpy.freeze();
        this.setState({ sidePanel: sidePanelContent, sidePanelStyle: style, sidePanelId: id });
    }

    closeSidePanel() {
        let settings = {
            targets: '.side-panel',
            easing: 'easeOutQuad',
            duration: 600,
            complete: () => {
                ScrollSpy.unFreeze();
                this.setState({ sidePanel: null, sidePanelId: null });
            }
        }
        if (!mediaQuery.isMobile()) {
            settings.width = '0px';
        } else {
            settings['max-height'] = '0vh';

        }

        anime({
            targets: '.main-menu, .side-panel div',
            easing: 'easeOutQuad',
            opacity: [1, 0],
            duration: 300,
            complete: () => {
                anime(settings);

            }
        })



    }


    render() {
        let color = this.state.sidePanel === this.calenderSidePanel ? styles.greenBackground : this.props.color;

        return (<nav>
            <ul className="side-menu">
                {/* <NavItem backgroundColor={styles.greenBackground} color={color} active={this.state.sidePanelId === 'calender'} handleClick={this.calenderButtonClick} label="KALENDER" icon={<CalenderIcon />} /> */}
                <NavItem backgroundColor={styles.pinkBackground} color={color} active={this.state.sidePanelId === 'video-stories'} handleClick={this.videosClick} label="VIDEO FORTELLINGER" icon={<FilmSolid style={{ fill: styles.menuBlack }} />} />
                <NavItem backgroundColor={styles.lightBrown} color={color} active={this.state.sidePanelId === 'stories'} handleClick={this.storiesClick} label="LIVSHISTORIER" icon={<CommaIcon />} />
                <NavToggle color={this.state.sidePanel !== null ? styles.frantzYellow : this.props.color} menuState={this.state.sidePanel !== null} handleClick={this.menuButtonClick} />
                <NavItem handleClick={this.exhibitionsClick} target="_self" color={color} label="NETTUTSTILLINGER" icon={<ExhIcon />} />
                <NavItem backgroundColor={styles.yellowBackground} color={color} label="SØK" handleClick={this.searchButtonClick} icon={<SearchIcon />} />
            </ul>

            {this.state.sidePanel !== null ? <SidePanel closeMenu={this.closeSidePanel} style={this.state.sidePanelStyle}>{this.state.sidePanel}</SidePanel> : null}

        </nav>);
    }
}
