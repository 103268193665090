import React from 'react';

export default class Container extends React.Component {
 
  render() {
    let className = "container";
    if (this.props.className) {
      className += ` ${this.props.className}`;
    }
    
    return (<div ref={this.props.passedRef} className={ className} style={this.props.style}>
      {this.props.children}
    </div>);
  }
}
