import React from 'react';
import Slide from './slide';
import SliderDot from './slider-dot';
import { Swipeable } from 'react-swipeable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default class Slider extends React.Component {
    constructor() {
        super();
        this.state = { currentSlide: 0 };
        this.renderSlides = this.renderSlides.bind(this);
        this.renderDots = this.renderDots.bind(this);
        this.nextSlide = this.nextSlide.bind(this);
        this.prevSlide = this.prevSlide.bind(this);
        this.toSlide = this.toSlide.bind(this);
        this.onSwipe = this.onSwipe.bind(this);
    }

    onSwipe(e) {
        if (e.dir === "Right") {
            this.prevSlide();
        }
        if (e.dir === "Left") {
            this.nextSlide();
        }
    }

    renderSlides() {
        return this.props.slides.map((element, index) => {
            let slideStyle = {
                position: 'absolute',
                left: (index - this.state.currentSlide) * window.innerWidth
            }
            return <Slide key={`slide-${index}`} style={slideStyle}>{element}</Slide>
        });
    }

    renderDots() {
        return this.props.slides.map((element, index) => {
            return <SliderDot key={`dot-${index}`} onClick={() => this.toSlide(index)} active={this.state.currentSlide === index} />
        });
    }

    nextSlide(e) {
        if (e && typeof e.stopPropagation === 'function') {
            e.stopPropagation();
        }

        let next = this.state.currentSlide + 1;
        if (next > this.props.slides.length - 1) {
            next = 0;
        }
        this.setState({ currentSlide: next });


    }

    prevSlide() {
        let next = this.state.currentSlide - 1;
        if (next < 0) {
            next = this.props.slides.length - 1;
        }
        this.setState({ currentSlide: next });
    }

    toSlide(index) {
        if (index > this.props.slides.length - 1) {
            index = 0;
        }
        this.setState({ currentSlide: index });

    }

    render() {
        let className = this.props.className !== undefined ? `slider ${this.props.className}` : 'slider';
        return (
            <Swipeable className={className} onSwiped={this.onSwipe}>
                <div className="slides-container">
                    {this.renderSlides()}
                </div>
                {this.props.slides.length > 1 && <div className="slider-side-controls">
                    <div className="prev-slide" onClick={this.prevSlide}><FontAwesomeIcon icon={faChevronLeft} /></div>
                    <div className="next-slide" onClick={this.nextSlide}><FontAwesomeIcon icon={faChevronRight} /></div>
                </div>}
                {this.props.slides.length < 6 && this.props.slides.length > 1 && <div className="slider-footer-controls">
                    {this.renderDots()}
                </div>}
            </Swipeable>);
    }
}
