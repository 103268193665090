import dayjs from 'dayjs';
import React from 'react'
import { getArticles } from '../helpers/api';
import Loading from '../layout/loading';
import SectionHeading from '../layout/section-heading';
import EventCard from './event-card';
export default class CalenderPanel extends React.Component {
    constructor() {
        super();
        this.state = { opacity: 0, articles:[] };
    }

    async getData() {
        let articles = await getArticles('events');
       
        this.setState({
            articles: articles.sort((a, b) => {
                return dayjs(b.start).unix()  - dayjs(a.start).unix()  
            })
        });
    }
    componentDidMount() {
        this.getData();
        setTimeout(() => this.setState({ opacity: 1 }), 500);
    }

    renderArticles() {
        return this.state.articles.map(article => <EventCard  key={article._id} {...article} />);
    }

    render() {
        return (
            <div className="calender-panel" style={{ opacity: this.state.opacity }}>
                <div className="events">
                <SectionHeading link="/kalender">KALENDER</SectionHeading>
                <div className="events-container">
                    {this.state.articles.length > 0 ? this.renderArticles() : <Loading />}
                </div>
                    </div>
            </div>
        );
    }
}
