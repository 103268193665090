import React from 'react'
import Container from './layout/container';
import Section from './layout/section';

export default class Project extends React.Component {
    render() {
      return (
      <Section className="page-intro about">
        <Container>
          <h1>Prosjekter</h1>

        </Container>
      </Section>
      );
    }
  }
