import React from 'react'
import anime from 'animejs/lib/anime.es.js';

export default class NavItemText extends React.Component {
  constructor() {
    super();
    this.content = React.createRef();
  }

  componentDidUpdate(prevProps)
  {
    if(prevProps.show && !this.props.show){
      anime({
        targets:this.content.current,
        duration: 300,
        easing: 'easeOutQuad',
        opacity: [1,0],
        translateX: ['0%', '10%'],
        right: ['6.5rem','5rem'],
        
      })
    } else if(!prevProps.show && this.props.show){
      anime({
        targets:this.content.current,
        duration: 300,
        easing: 'easeOutQuad',
        opacity: [0,1],
        right: ['5em','6.5rem'],
        translateX: ['10%', '0%'],
      })
    }
    
  }
  

  render() {
    if (!this.props.show) return null;

      return <span style={{opacity:0}} className="menu-text" ref={this.content}>{this.props.children}</span>;
  }
}
