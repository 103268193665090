import React from 'react';

export default class Slide extends React.Component {

    render() {
        return (
            <article style={this.props.style} className="slide">
                {this.props.children}
            </article>);
    }
}
