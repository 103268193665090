import React from 'react';

export default class SliderDot extends React.Component {
  
    render() {
        let className = this.props.active ? 'slider-dot active' : 'slider-dot';
        return (
            <div onClick={this.props.onClick} className={className}>
                <span/>
            </div>);
    }
}
