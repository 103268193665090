import React from 'react'
import SiteLogo from './layout/site-logo';
import PageHeading from './layout/page-heading';
import Section from './layout/section';
import { getStoryQuote } from './components/stories-slider';
import { createCategories, extractSearch, getArticles } from './helpers/api';
import Loading from './layout/loading';
import mediaQuery from './helpers/media-query';
import VideoStory from './components/video-story';
import Slider from './components/slider/slider';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
export default class Videos extends React.Component {

    constructor() {
        super();
        this.state = { articles: [], showPageMenu: false, filter: (article) => true }
    }



    componentDidUpdate(prevProps, prevState) {
        let { category } = extractSearch(this.props.location.search);
        if (prevState.category !== category) {
            let filter;
            if (category === undefined) {
                filter = () => true;
            }
            else {
                filter = (article => article.categories.length === 0 || article.categories.some(cat => cat.name.toLowerCase() === category));
            }
            this.setState({ category, filter });
        }
    }

    async componentDidMount() {
        let articles = await getArticles('video-stories');
        let categories = createCategories(articles);
        this.setState({ articles: articles.sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix()), categories })
        setTimeout(() => { this.setState({ showPageMenu: true }) }, 500);
    }

    render() {
        //let filtered = this.state.articles?.filter(this.state.filter);
        //let categories = this.state.categories?.map(cat => <SubMenuItem isActive={() => this.state.category === cat.name.toLowerCase()} key={cat._id} label={cat.name} link={`?category=${cat.name?.toLowerCase()}`} />) || []
        /*let menuItems = [
            <SubMenuItem isActive={() => this.state.category === undefined} key="all-videos" label="Alle" link={this.props.location.pathname} />,
            ...categories
        ]*/
        let slides = this.state.articles ?
            this.state.articles.map((story, index) => {
                let backgroundQuote = getStoryQuote(this.state.articles?.length - index);

                return (<VideoStory key={"video-story-" + index} useSlug={true} backgroundQuote={backgroundQuote} {...story} />)
            }) : <Loading />;

        return (
            <div className="content video-stories-page">
                <Helmet>
                    <title>VIDEO FORTELLINGER</title>
                    <link rel="canonical" href={`https://beheard.no/video-fortellinger`} />
                    <meta property="og:title" content="Be Heard - Video Fortellinger" />
                    <meta property="og:url" content={`https://beheard.no/video-fortellinger`} />

                    <meta name="twitter:title" content="Be Heard - Video Fortellinger" />
                </Helmet>
                <Section className="video-stories-content">
                    <div style={{ paddingRight: this.state.showPageMenu && !mediaQuery.isMobile() ? '0' : '0', transition: 'all .3s ease' }}>
                        <SiteLogo />
                        <PageHeading>VIDEO FORTELLINGER</PageHeading>
                        {mediaQuery.isMobile() ? <Slider slides={slides}></Slider> :
                            <div className="video-stories-container">
                                {slides}
                            </div>
                        }



                    </div>

                </Section>



            </div>
        );
    }
}
