import React from 'react';

export default class ImageGrained extends React.Component {
  
    componentDidMount() {
     

    }

    render() {
        if (this.props.src) {
            return (
                <div {...this.props}>
                    <picture className="grained">
                        <img src={this.props.src} alt={this.props.alternativeText}/>
                    </picture>
                </div>)
        }
        if (this.props.style) {
            return (<div style={this.props.style} className={this.props.className + ' grained'}>
               
            </div>)
        }
        return null;
            
    }
}
