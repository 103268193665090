import React from 'react'

export default class SingleImage extends React.Component {


  render() {
    let src = this.props.formats?.large?.url || this.props.url;
    return <div className="dynamic-content-image">
      <img src={src} alt={this.props.alt} />
      {(this.props.alternativeText || this.props.caption) && <div className="image-info">
        <p>{this.props.alternativeText}</p>
        <p>{this.props.caption}</p>
      </div>}
    </div>
  }
}
