import React from 'react';
import Loading from './layout/loading';
import SiteLogo from './layout/site-logo';
import DynamicContent from './dynamic-content';
import Section from './layout/section';
import PageHeading from './layout/page-heading';
import anime from 'animejs';
import ReactMarkdown from 'react-markdown';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';

export default class Page extends React.Component {
    constructor() {
        super();
        this.state = { loading: true };
        this.fetchPage = this.fetchPage.bind(this);
        this.urlPrefix = process.env.NODE_ENV === 'development' ? '' : 'https://beheard.no/api'

    }

    componentDidMount() {
        this.fetchPage();
    }

    async fetchPage() {
        let response;
        if (this.props.id !== undefined) {
            response = await fetch(`${this.urlPrefix}/pages/${this.props.id}`);

        } else {
            const { slug } = this.props.match.params
            response = await fetch(`${this.urlPrefix}//pages?slug=${slug}`);

        }
        let results = await response.json();

        let page;
        if (Array.isArray(results)) {
            page = results[0];
        }
        else {
            page = results;
        }

        if (page === undefined) {
            return;
        }

        page.publishedOn = dayjs(page.publishedOn)
        this.setState({ page, loading: false });
        anime({
            delay: 1000,
            targets: '.page-content',
            opacity: [0, 1],
            duration: 300,
            translateY: ['200px', '0px'],
            easing: 'linear',
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading />
        }

        if (this.state.page === undefined) {
            return <h2>Could not find that page</h2>
        }
        return (
            <article className="article">
                <Helmet>
                    <title>{this.state.page.title}</title>
                    {this.state.page.featuredImage &&
                        <meta property="og:image" content={"https://beheard.no/" + this.state.page.featuredImage.formats.medium.url} />}
                    {this.state.page.featuredImage &&
                        <meta name="twitter:image" content={"https://beheard.no/" + this.state.page.featuredImage.formats.medium.url} />
                    }
                </Helmet>
                <SiteLogo />

                <Section className="page-intro">
                    <PageHeading>{this.state.page.title.toUpperCase()}</PageHeading>
                </Section>

                <Section className="page-content">
                    {
                        this.state.page.pageIntro && <div className={"page-intro-text " + this.state.page.pageIntroLayout || 'normal'}>
                            <ReactMarkdown source={this.state.page.pageIntro} />
                        </div>
                    }
                    <DynamicContent content={this.state.page.content} />
                </Section>
            </article>

        );

    }
}
