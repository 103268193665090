import React from 'react';
import mediaQuery from '../helpers/media-query';

export const allowedComponents = ['content.text', 'content.text-and-image'];

export default class ContentIndex extends React.Component {

    componentDidMount() {
     
    }

    componentWillUnmount() {
      
    }

    scrollToIndex(id) {
        let sectionSelector = `section-${id}`;
        document.getElementById(sectionSelector).scrollIntoView()
    }

    onClick() {
        if (mediaQuery.isHandheld()) {
            this.props.toggleIndex();
        }
    }

    render() {
        
        let className = this.props.open ? 'content-index open' : 'content-index closed';
        return (
            <div className={className}>
                <ul>
                    <li><h3>KAPITLER</h3></li>
                    {
                        this.props.content.filter(item => allowedComponents.includes(item.__component) && item.sectionTitle).map((item, index) => {
                            let inView = item._id === this.props.currentSection ? 'active' : 'in-active';
                            let id = `#section-` + item._id
                            return <li onClick={this.onClick.bind(this)} className={inView} key={`index-` + item._id}>
                                <a href={id} >{item.sectionTitle}</a>
                            </li>
                        })
                    }

                </ul>
            </div>
        );
  }
}
