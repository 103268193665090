import React from 'react'
import { NavLink } from 'react-router-dom';
import { scrollTo } from '../helpers/helpers';

export default class SubMenuItem extends React.Component {
  constructor() {
    super();
    this.menuItemRef = React.createRef();
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {

  }

  handleClick() {
    scrollTo('body', 600);
    if (typeof this.props.handleClick === 'function') {
      this.props.handleClick();
    }
  }

  render() {
    let content = (
      <NavLink isActive={this.props.isActive} exact={true} onClick={this.handleClick} activeClassName='active' to={this.props.link}>
        {this.props.icon}
        {this.props.label}
      </NavLink>
    );
    return (
      <li className="sub-menu-item" ref={this.menuItemRef}>
        {content}
      </li>
    );
  }
}
