import React from 'react'
import SectionHeading from '../layout/section-heading';
import { getArticles } from '../helpers/api';
import Loading from '../layout/loading';
import VideoStory from './video-story';
import dayjs from 'dayjs'
import nalensMakt from '../images/makt-2.png'
import skin from '../images/skin-2.png'
export default class ExhibitionsPanel extends React.Component {
    constructor() {
        super();
        this.state = { opacity: 0, articles: [] };
    }


    componentDidMount() {
        setTimeout(() => this.setState({ opacity: 1 }), 500);
    }



    render() {


        return (
            <div className="exhibitions">
                <SectionHeading>NETTUTSTILLINGER</SectionHeading>
                <div className="exhibitions-container">
                    <div className="exhibition">
                        <a href="https://beheard.no/nalens-makt" target="self" class="exhibition-content">
                            <img src={nalensMakt} />
                            <h3>Nålens Makt</h3>
                            <p>Dagens kvinner broderer fortellinger om identitet, motstand og kulturarv</p>
                        </a>
                    </div>
                    <div className="exhibition">
                        <a href="https://beheard.no/skin-remembers" target="_self" class="exhibition-content">
                            <img src={skin} />

                            <h3>Skin Remembers</h3>
                            <p>Individer med minoritetsbakgrunn fra Irak, Palestina, Syria og Sàpmi forteller om tanker og følelser knyttet til sin identitet.</p>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
