import anime from 'animejs/lib/anime.es.js';

class MSScrollSpy {

    constructor() {
        this.trackingNodes = [];
        this.trackingTraveled = [];
        this.previousScrollY = 0;
        this.trackingStart = window.scrollY;
        this.traveled = 0;
        this.traveledTimeout = null;
        this.direction = 'down';
        this.previousDirection = null;
        this.run = this.run.bind(this);
        this.initAnimations = this.initAnimations.bind(this);
        this.inView = this.inView.bind(this);
        this.trackTraveled = this.trackTraveled.bind(this);
        this.trackNode = this.trackNode.bind(this);
        this.setDirection = this.setDirection.bind(this);
        this.resetTravelTracking = this.resetTravelTracking.bind(this);
        this.scrollTo = this.scrollTo.bind(this);
        this.freeze = this.freeze.bind(this);
        this.unFreeze = this.unFreeze.bind(this);

        this.initAnimations();
        this.run();

        window.addEventListener('scroll', this.run, { passive: true });

        document.addEventListener('DOMContentLoaded', () => { this.run(); });
    }

    run(e) {
        this.setDirection();
        if (this.previousDirection !== this.direction) {
            this.resetTravelTracking();
        }
        this.traveled = window.scrollY - this.trackingStart;
        this.previousScrollY = window.scrollY;

        if (this.traveledTimeout !== null) {
            clearTimeout(this.traveledTimeout);
            this.traveledTimeout = null;
        }

        this.traveledTimeout = setTimeout(() => {
            this.resetTravelTracking();
        }, 500);

        this.trackingNodes.forEach(item => {
            if (item.trigger === 'in-view') {

                if (!item.visible && this.inView(item)) //|| (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight)
                {
                    item.visible = true;

                    if (typeof item.actionIn === 'function') {
                        item.actionIn();
                    }
                }

                if (!this.inView(item) && (item.visible || item.visible === undefined)) {
                    item.visible = false;

                    if (typeof item.actionOut === 'function') {
                        item.actionOut();
                    }
                }
            }

            if (item.trigger === 'top') {
                if (window.scrollY <= 0) {
                    if (item.actionIn !== undefined) {
                        item.visible = true;
                        item.actionIn();
                    }
                } else {

                }
            }
        });

        this.trackingTraveled.forEach(action => {
            if (typeof action === 'function') {
                action(this.traveled);
            }
        });
    }

    resetTravelTracking() {
        this.traveled = 0;
        this.trackingStart = window.scrollY;
    }

    setDirection() {
        let direction = window.scrollY - this.previousScrollY > 0 ? 'up' : 'down';
        if (this.previousDirection === null) {
            this.previousDirection = direction;
        } else {
            this.previousDirection = this.direction;
        }
        this.direction = direction;
    }

    trackTraveled(action) {
        this.trackingTraveled.push(action);
    }

    inView(item) {
        if (item === undefined) {
            return false;
        }

        let offset = item.offset || 0;
        let clientRect = item.node.getBoundingClientRect();
        if ((clientRect.top < window.innerHeight - offset && clientRect.bottom > offset) ||
            (clientRect.bottom > offset && clientRect.top < 0)) {
            return true;
        }

        //if (clientRect.bottom > window.scrollY) {
        //    item.viewed = true;
        //    return true;
        //}

        return false;
    }

    trackNode(node, actionIn, trigger, actionOut, offset) {
        this.trackingNodes.push({ node, actionIn, viewed: false, offset, trigger, actionOut });
    }

    stopTracking(node) {
        this.trackingNodes = this.trackingNodes.filter(n => n.node !== node);
    }

    initAnimations() {
        let animateInElements = document.querySelectorAll('.animate');

        if (animateInElements !== null) {
            [...animateInElements].forEach(node => {

                //Get the animation data

                let animations = node.dataset.animation.split(" ");
                let animeVars = {
                    targets: node,
                    duration: node.dataset.duration !== undefined ? parseInt(node.dataset.duration) : 300,
                    easing: node.dataset.easing !== undefined ? node.dataset.easing : 'easeOutQuad',
                    delay: node.dataset.delay !== undefined ? parseInt(node.dataset.delay) : 0,
                };
                if (node.tagName === 'UL') {
                    animeVars.targets = [node, ...node.querySelectorAll('li')];
                    animeVars.delay = anime.stagger(200);
                }

                animations.forEach((animation) => {
                    switch (animation) {
                        case 'fade-in':
                            node.style.opacity = 0;
                            animeVars.opacity = [0, 1];
                            break;

                        case 'slide-in':
                            node.style.opacity = 0;
                            animeVars.opacity = [0, 1];

                            let direction = node.dataset.direction;
                            if (direction !== undefined) {
                                if (direction === 'from-left') {
                                    animeVars.translateX = ['-15%', '0%'];
                                }

                                if (direction === 'from-right') {
                                    animeVars.translateX = ['15%', '0%'];
                                }

                                if (direction === 'from-top') {
                                    animeVars.translateY = ['-15%', '0%'];
                                }

                                if (direction === 'from-bottom') {
                                    animeVars.translateY = ['15%', '0%'];
                                }
                            }

                            break;

                        case 'scale-in':
                            node.style.transform = 'scale(0)';
                            animeVars.scale = [0, 1];
                            animeVars.opacity = 1;
                            break;
                        default:
                            return;
                    }
                });

                this.trackNode(node, () => {
                    anime(animeVars);
                }, node.dataset.trigger !== undefined ? node.dataset.trigger : 'in-view');

            });
        }
    }

    freeze() {
        this.scrollingEl = window.document.scrollingElement || window.document.body || window.document.documentElement;
        this.scrollingEl.style.overflow = 'hidden';

    }

    unFreeze() {
        this.scrollingEl.style.overflow = 'visible';
        //let main = document.querySelector('.main');

    }

    scrollTo(element) {
        let scrollingEl = window.document.scrollingElement || window.document.body || window.document.documentElement;
        let rect = element.getBoundingClientRect();
        let offset = scrollingEl.scrollY + rect.top;
        anime({
            targets: scrollingEl,
            duration: 300,
            easing: 'linear',
            scrollTop: offset
        });
    }
};

export default new MSScrollSpy();

