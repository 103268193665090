import React from 'react';
export default class ImageThumbs extends React.Component {

  componentDidMount(){
  }

    render() {
        
        let thumbs = this.props.images.map((image) => {
          return <img style={this.props.thumbStyle} onClick={(e) => { e.stopPropagation(); this.props.onClick(image); }} alt={image.alternativeText} key={image._id} className="image-thumb" src={image.formats.thumbnail.url} />;
        });

        return (<div className="image-thumbs">
            {thumbs}
            </div>);
            }
            }
