import React from 'react';
import dance from '../images/dance.png';
import ImageGrained from './images/image-grained';
import dayjs from 'dayjs';
export default class EventCardBordered extends React.Component {
  
    render() {
        let imageSrc = this.props.featuredImage ? this.props.featuredImage.url : dance
        let link = `/kalender/${this.props.slug}`;
        let startDate = dayjs(this.props.start);
        return (<a href={link} className="event-card-bordered">
            <div className="img-container" >
                    <picture>
                        <img src={imageSrc} />
                    </picture>
                </div>
            <div className="event-info">
                <h2>{this.props.title}</h2>
                <div className="readable-date"><span className="day">{startDate?.format('DD MMMM YYYY')}</span></div>
                <p>{this.props.description}</p>
            </div>
            <div className="article-date">
                <span className="day">{startDate?.format('DD')}</span>
                <span className="month">{startDate?.format('MM')}</span>
                <span className="divider"></span>
                <span className="year-one">{startDate?.year().toString().slice(0, 2)}</span>
                <span className="year-two">{startDate?.year().toString().slice(2 - 4)}</span>
            </div>
        </a>);
    }
}
