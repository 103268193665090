import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

export default class ArticleLink extends React.Component {

    render() {
        return (
            <div className="flex-row article-link story-link">
                <p>{this.props.label} <FontAwesomeIcon icon={faChevronRight} /></p>
            </div>
        );
    }
}
