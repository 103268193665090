import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SubMenuItem from './menu/sub-menu-item';
import Loading from './layout/loading';
import Page from './page';
import mediaQuery from './helpers/media-query';
import styles from './helpers/styles';
import SidePanelService from './menu/side-panel-service';
import GenericPanel from './components/generic-panel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import PageMenuButton from './layout/page-menu-button';
import { Helmet } from 'react-helmet';

export default class About extends React.Component {
    constructor() {
        super();
        this.getContent = this.getContent.bind(this);
        this.state = { content: [], loading: true, menuItems: [], showPageMenu: false, lastScrollPos: window.scrollY };
        this.urlPrefix = process.env.NODE_ENV === 'development' ? '' : 'https://beheard.no/api'
        this.doScroll = this.doScroll.bind(this);
        this.contentRef = React.createRef();
    }

    componentDidMount() {
        this.getContent();
        setTimeout(() => {
            this.setState({ showPageMenu: true });
        }, 1000);


        //window.addEventListener('scroll', this.doScroll);

    }

    componentDidUpdate() {

    }

    componentWillUnmount() {
        // window.removeEventListener('scroll', this.doScroll);

    }

    doScroll(e) {
        if (mediaQuery.isDesktop()) {
            return;
        }
        let scrollPos = this.state.lastScrollPos;
        this.setState({ lastScrollPos: window.scrollY });

        if (scrollPos - this.state.lastScrollPos > 0) {
            this.setState({ showPageMenu: true });
        } else {
            this.setState({ showPageMenu: false });


        }

    }

    async getContent() {
        let response = await fetch(`${this.urlPrefix}/about`);
        let about = await response.json();
        this.setState({
            loading: false,
            menuItems: about.pages.map(page => <SubMenuItem handleClick={() => SidePanelService.closeSidePanel()} key={page._id} label={page.title} link={`/om/${page.slug}`} />),
            routes: about.pages.map(page =>
                <Route key={page._id} path={`/om/${page.slug}`}>
                    <Helmet>
                        <title>{page.title}</title>
                        <link rel="canonical" href={`https://beheard.no/om/${page.slug}`} />
                    </Helmet>
                    <Page id={page.id} />
                </Route>),
            pages: about.pages
        });
    }

    toggleMenu() {


        const menu = <GenericPanel title="Om Be Heard" className="om-pages page-menu">
            <ul>
                {this.state.menuItems}
            </ul>
        </GenericPanel>

        SidePanelService.setSidePanel(menu, { backgroundColor: styles.lightGreen, width: mediaQuery.isMobile() ? 400 : 300 }, 'om-pages');
    }


    render() {
        if (this.state.loading) {
            return <Loading />
        }
        return (
            <div className="content about-content">
                <Helmet>
                    <title>Om</title>
                    <link rel="canonical" href="https://beheard.no/om" />

                    <meta property="og:title" content="Be Heard - Om" />
                    <meta property="og:description" content="Be Heard er et konsept som omfavner ARKIVETs arbeid innenfor fagfeltet migrasjon, inkludering og mangfold." />
                    <meta property="og:url" content="https://beheard.no/om" />

                    <meta name="twitter:title" content="Be Heard" />
                    <meta name="twitter:description" content="Be Heard er et konsept som omfavner ARKIVETs arbeid innenfor fagfeltet migrasjon, inkludering og mangfold." />


                </Helmet>
                <div ref={this.contentRef} className="content about-left">
                    <Switch>
                        {this.state.routes}
                        <Route path="/om">
                            <Redirect to={`/om/${this.state.pages[0].slug}`} />
                        </Route>
                    </Switch>
                </div>
                <PageMenuButton show={this.state.showPageMenu} onToggle={this.toggleMenu.bind(this)} icon={<FontAwesomeIcon icon={faEllipsisV} color={styles.menuBlack} />} />

            </div>
        );
    }
}
/*
 *   <Section className="page-intro about">
                <Container>
                    <h2>Om</h2>

                </Container>
            </Section>
 *
                    */