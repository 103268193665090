import React from 'react';

export default class SiteLogo extends React.Component {

  componentDidMount(){
      this.heading = document.querySelector('.site-logo');

  }

  render() {
      return (<a href="/" className="site-logo"><h1>BE HEARD!</h1></a>);
  }
}
