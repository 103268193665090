import React from 'react';
import StoryCard from './story-card';
import Slider from './slider/slider';
import { ReactComponent as ApostrophyRound } from '../images/apostrophy-fill-curved.svg';
import { ReactComponent as ApostrophyCurved } from '../images/apostrophy-fill-round.svg';
import { ReactComponent as CommaCurved } from '../images/comma-fill-curved.svg';
import { ReactComponent as CommaSharp } from '../images/comma-fill-sharp.svg';

import ApostrophyRoundUrl from '../images/apostrophy-fill-curved.svg';
import ApostrophyCurvedUrl from '../images/apostrophy-fill-round.svg';
import CommaCurvedUrl from '../images/comma-fill-curved.svg';
import CommaSharpUrl from '../images/comma-fill-sharp.svg';
const images = [ApostrophyCurved, CommaCurved, ApostrophyRound, CommaSharp];
const imageUrls = [ApostrophyCurvedUrl, CommaCurvedUrl, ApostrophyRoundUrl, CommaSharpUrl];
const orientations = ['left', 'right'];

export function getStoryQuote(index) {
    let imageIndex = index;
    if (imageIndex > images.length - 1) {
        imageIndex = index % images.length;
    }

    return images[imageIndex];
}

export function getStoryQuoteUrl(index) {
    let imageIndex = index;
    if (imageIndex > images.length - 1) {
        imageIndex = images.length % index;
    }
    return imageUrls[imageIndex];
}
export function getStoryOrientation(index) {
    return orientations[index % 2];
}


export default class StoriesSlider extends React.Component {

    componentDidMount() {
    }

    render() {
        if (!this.props.articles) {
            return null;
        }



        let slides = this.props.articles?.map((story, index) => {
            let BackgroundQuote = getStoryQuote(index);
            let svgUrl = story.quoteMask?.url || getStoryQuoteUrl(index);
            let orientation = getStoryOrientation(index);
            let style = {};

            if (index % 2) {
                style.left = 0;
            } else {
                style.right = 0;
            }
            let quoteId = story.id + '-quote';
            return (
                <StoryCard backgroundQuote={<BackgroundQuote id={quoteId} className="quote-background" />} orientation={orientation} {...story} quoteSvg={svgUrl} />
            );
        });
        return (<Slider className="stories-slider" slides={slides} />);
    }
}
