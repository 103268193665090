import React from 'react';
import Player from '@vimeo/player';
import ReactMarkdown from 'react-markdown';

export default class VideoStory extends React.Component {

    constructor() {
        super();
        this.state = { playing: false };
        this.onPlay = this.onPlay.bind(this);
        this.onStop = this.onStop.bind(this);
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        let container = this.videoRef.current;
        if (!container) return;
        this.iframe = container.querySelector('iframe');
        const player = new Player(this.iframe);

        player.on('play', this.onPlay);
        player.on('pause', this.onStop);

    }


    onPlay() {
        this.setState({ playing: true });
    }

    onStop() {
        this.setState({ playing: false });
    }

    render() {

        let className = "video-story";
        let BackgroundQuote = this.props.backgroundQuote;
        if (this.state.playing) {
            className += " playing";
        }

        return (<div className={className}>

            <div className="video-container">
                {BackgroundQuote && <BackgroundQuote id={'background-' + this.props._id} className="quote-background" />}

                {/* <video width="400" height="auto" controls onPlay={this.onPlay} onPause={this.onStop} onStop={this.onStop}>
                    <source src={this.props.video?.url + '#2'}></source>
                    <p>You browser does not support this video tag</p>
        </video>*/}
                <div ref={this.videoRef} className="video" dangerouslySetInnerHTML={{ __html: this.props.video }} />


            </div>

            <div className="video-content">
                {this.props.useSlug && this.props.slug ? <h3><a href={`/video-fortellinger/${this.props.slug}`}>{this.props.title}</a></h3> : <h3>{this.props.title}</h3>}
                <ReactMarkdown source={this.props.description} />
            </div>

        </div>);
    }
}

