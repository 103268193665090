import React from 'react'

export default class StoryHeading extends React.Component {
  constructor() {
      super();
      this.state = {};
    
  }

  componentDidMount(){


    }
    componentWillUnmount() {
    }



    render() {
     

        return (
            <section className="story-heading-container">
                <div className="story-heading">
                    <h1>{this.props.story.title}</h1>
                    <h2>{this.props.story.subTitle}</h2>
                </div>
            </section>
        );
  }
}
