import React from 'react';
import SiteLogo from './layout/site-logo';
import Section from './layout/section';
import PageHeading from './layout/page-heading';
import styles from './helpers/styles';
import nettImage from './images/nettutstilling.jpg';

export default class ComingSoon extends React.Component {
 
    constructor() {
        super();
        this.state = {articles:[], filter: (article)=>true}
    }

    render() {
      
        let style = { backgroundColor: styles.blackBackground };
        return (
            <div style={style} className="content coming-soon-page">

                <Section className="coming-soon-content">
                    <div style={{paddingRight: this.state.showPageMenu ? '0' : '0', transition: 'all .3s ease'}}>
                        <SiteLogo />
                        <PageHeading>COMING SOON</PageHeading>
                    </div>

                </Section>
                
                <Section className="coming-soon-section">
                    <img src={nettImage} alt="frontpage of skin remembers exhibition" />
                </Section>

              
            </div>


        );
    }
}
