import React from 'react';
import anime from 'animejs';
import StoryCard from './story-card';
import { getStoryQuoteUrl } from './stories-slider';

export default class StoriesContainer extends React.Component {

    componentDidMount() {
        anime({
            delay: anime.stagger(200, { start: 500 }),
            targets: '.story-card-small',
            opacity: [0, 1],
            duration: 300,
            translateX: ['100px', '0px'],
            easing: 'linear',
        });
    }

    render() {
        let stories = this.props.stories.map((story, index) => {
            let svgUrl = getStoryQuoteUrl(index);

            return <StoryCard key={story._id} {...story} orientation="left" quoteSvg={svgUrl} />
        });
    return (<div className="stories-container">
        {stories}
    </div>);
  }
}
