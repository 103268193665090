import React from 'react'
import anime from 'animejs/lib/anime.es.js';

export default class PageHeading extends React.Component {
  constructor() {
      super();
      this.state = { fontSize: 1 };
      this.heading = React.createRef();
      this.doScroll = this.doScroll.bind(this);
  }

    componentDidMount() {
        if (this.heading.current === null) {
            return;
        }
    anime({
        targets: this.heading.current,
        duration: 600,
        delay: 100,
        //opacity:[0,1],
        scale: [5, 1],
        easing: 'easeOutBounce',
        complete: () => {
            if (this.heading.current === null) return;
            this.initialTop = this.heading.current.getBoundingClientRect().top + window.scrollY;
        }
    });
      window.addEventListener('scroll', this.doScroll);
      window.addEventListener('wheel', this.doScroll);

    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.doScroll);
        window.removeEventListener('wheel', this.doScroll);
    }

    doScroll() {
        if (this.heading.current === null) return;
        let top = this.heading.current.getBoundingClientRect().top;
        let fontSize = this.state.fontSize;
        if (top > 0) {
            fontSize = (top / this.initialTop)
            if (fontSize < 0.5) {
                fontSize = 0.5;
            }
            this.setState({ fontSize });
        }
    }


    render() {
        
        let style = {
            transform: `scale(${this.state.fontSize})`,
            transition: 'all .3s ease'
        };
        return (<div ref={this.heading} className="page-heading-container">
            <h1 style={style} className="page-heading">
                {this.props.children}
            </h1>
        </div>);
  }
}
