import React from 'react';
import dance from '../images/dance.png';
import dayjs from 'dayjs';
export default class EventCard extends React.Component {

    render() {
        let imageSrc = this.props.featuredImage ? this.props.featuredImage.url : dance
        let link = `/kalender/${this.props.slug}`;
        let start = dayjs(this.props.start);

        return (<a href={link} className="event-card">
            <div className="image-date-container">
                <picture className="img-container">
                    <img src={imageSrc} alt={this.props.featuredImage ? this.props.featuredImage.alternativeText : 'A dancer'} />
                </picture>
                <div className="article-date">
                    <span className="day">{start.format('DD')}</span>
                    <span className="month">{start.format('MM')}</span>
                    <span className="divider"></span>
                    <span className="year-one">{start.year().toString().slice(0, 2)}</span>
                    <span className="year-two">{start.year().toString().slice(2 - 4)}</span>
                </div>
            </div>
            <div className="event-info">
                <h2>{this.props.title}</h2>
                <div className="readable-date"><span className="day">{start.format('DD MMMM YYYY')}</span></div>
                <p>{this.props.description}</p>
            </div>

        </a>);
    }
}
