import React from 'react';
import anime from 'animejs';
import ArticleCardDated from './article-card-dated';

export default class NewsContainer extends React.Component {

    componentDidMount() {
        anime({
            delay: anime.stagger(200, { start: 500 }),
            targets: '.news-card',
            opacity: [0, 1],
            duration: 300,
            translateX: ['100px', '0px'],
            easing: 'linear',
        });
  }

  render() {
    return (<div className="container news-container">
      {this.props.articles.map(article => <ArticleCardDated key={article._id} {...article} />)}
    </div>);
  }
}
