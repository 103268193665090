import React from 'react'
import SectionHeading from '../layout/section-heading';
import Loading from '../layout/loading';
import SubMenuItem from '../menu/sub-menu-item';
export default class SearchPanel extends React.Component {
    constructor() {
        super();
        this.state = { query: '', results: [], timeout: null, error: null };
        this.renderResults = this.renderResults.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getResults = this.getResults.bind(this);
        this.urlPrefix = process.env.NODE_ENV === 'development' ? '' : 'https://beheard.no/api'
        this.searchRef = React.createRef();
    }

    componentDidMount() {
        this.searchRef.current.focus();
    }

    renderResults() {
        if (this.state.error !== null) {
            return <p>There was an error getting the results</p>
        }
        if (this.state.query === '') {
            return <p>Type something</p>
        }
        if (this.state.loading) {
            return <Loading />
        }
        if (this.state.results.length === 0) {
            return <p>Couldn't find anything</p>
        }
        return this.state.results.map(result => {
            return <SubMenuItem handleClick={this.props.searchResultClick} key={result._id} label={`${result.type}: ${result.label}`} link={result.link} className="search-result" />;
        });
    }

    onChange(e) {
        this.setState({ query: e.target.value });

        if (this.state.timeout !== null) {
            clearTimeout(this.state.timeout);
            this.setState({ timeout: null });
        }

        if (this.state.query.length > 2 && this.state.timeout === null) {
            this.setState({ loading: true, timeout: setTimeout(this.getResults, 300) })
        }
    }

    async getResults() {
        try {
            let response = await fetch(`${this.urlPrefix}/search?_q=${this.state.query}`);
            let articles = await response.json();
            if (articles.error !== undefined) {
                this.setState({
                    results: [], loading: false
                });
                return;
            }
            this.setState({ results: articles, loading: false });
        } catch (e) {
            this.setState({ error: e, results: [], loading: false })
        }
    }


    render() {
        return (
            <div className="search-panel" style={{ opacity: this.state.opacity }}>
                <div className="search">
                    <SectionHeading>S&Oslash;K</SectionHeading>
                    <div className="search-input">
                        <input placeholder="Type something" autoComplete="off" ref={this.searchRef} name="site-search" type="text" onChange={this.onChange} value={this.state.query} />
                    </div>
                    <ul className="search-results-container">
                        {this.renderResults()}
                    </ul>
                </div>
            </div>
        );
    }
}
