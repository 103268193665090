import React from 'react';
import * as dayjs from 'dayjs';
import Loading from './layout/loading';
import StoryHeading from './layout/story-heading';
import DynamicContent from './dynamic-content';
import ContentIndex from './layout/content-index';
import PageMenuButton from './layout/page-menu-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './helpers/styles';
import mediaQuery from './helpers/media-query';
import { Helmet } from 'react-helmet';

export default class Story extends React.Component {
    constructor() {
        super();
        this.state = { loading: true, indexOpen: false, story: null, currentSection: null, isTop: true, currentSectionIndex: -1 };
        this.fetchStory = this.fetchStory.bind(this);
        this.toggleIndex = this.toggleIndex.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.dynamicContent = React.createRef();
        this.article = React.createRef();
        this.urlPrefix = process.env.NODE_ENV === 'development' ? '' : 'https://beheard.no/api'
        this.toggleIndex = this.toggleIndex.bind(this)
    }

    componentDidUpdate(prevProps) {
        const { slug } = this.props.match.params;
        const prevSlug = prevProps.match.params.slug;

        if (prevSlug !== slug) {
            this.fetchStory();
        }
    }

    componentDidMount() {
        this.fetchStory();
        if (typeof window !== undefined) {
            window.addEventListener('scroll', this.onScroll);
        }
    }

    componentWillUnmount() {
        if (typeof window !== undefined) {
            window.removeEventListener('scroll', this.onScroll);
        }
    }

    onScroll() {
        if (this.dynamicContent.current === null) {
            return;
        }
        let contentRect = this.dynamicContent.current.getBoundingClientRect();
        if (contentRect.top < 100) {
            if (!this.state.indexOpen) {
                this.setState({ indexOpen: !mediaQuery.isHandheld(), isTop: false });
            }
        } else {
            if (this.state.indexOpen) {
                this.setState({ indexOpen: false, isTop: true });
            }
        }

        let items = document.querySelectorAll('.indexable');
        if (items === null) {
            return;
        }

        items.forEach((item, index) => {
            let clientRect = item.getBoundingClientRect();
            let scrollPosition = this.article.current.scrollTop || 0;
            let top = clientRect.top + scrollPosition + (window.innerHeight / 2);
            let id = item.id.replace('section-', '');

            if (top > scrollPosition && top < scrollPosition + window.innerHeight) {
                if (this.state.currentSection !== id) {
                    this.setState({ currentSection: id, currentSectionIndex: index });
                }
            }

        });

    }



    async fetchStory() {
        const { slug } = this.props.match.params
        this.setState({ loading: true, indexOpen: false, story: null });

        let response = await fetch(`${this.urlPrefix}/stories?slug=${slug}`);
        let results = await response.json();
        let story = results[0];
        if (story === undefined) {
            return;
        }
        story.publishedOn = dayjs(story.publishedOn)
        this.setState({ story, loading: false });
    }


    //                    <button className="toggle" onClick={this.toggleIndex}>{this.state.indexOpen ? 'close' : 'Velg kapital'}</button>

    renderIndex() {


    }

    toggleIndex() {
        this.setState({ indexOpen: !this.state.indexOpen })
    }

    render() {
        if (this.state.loading) {
            return <Loading />
        }

        if (this.state.story === undefined) {
            return <h2>Could not find that story</h2>
        }

        //let storyStyle = { marginLeft: this.state.indexOpen ? 'calc(214px + 1em)' : 0 };
        let image = this.state.story.featuredImage?.formats?.large || this.state.story.featuredImage;
        let articleStyle = image ? { backgroundImage: `url(${image.url})` } : { backgroundColor: 'black', color: 'white' };
        let className = "story-article" + (this.state.story.featuredImage ? ' with-image' : ' without-image');
        let open = !this.state.isTop && this.state.indexOpen;

        let contentClass = "story-content-container";
        let storyBackgroundClass = 'story-background';
        if (this.state.currentSectionIndex > 0) {
            storyBackgroundClass += ' story-overlay';
        }

        if (open) {
            contentClass += " open";
        }
        return (

            <article ref={this.article} className={className}>
                <Helmet>
                    <title>{this.state.story.title}</title>
                    <link rel="canonical" href={`https://beheard.no/livshistorier/${this.state.story.slug}`} />

                    <meta property="og:title" content={this.state.story.title} />
                    <meta property="og:description" content={this.state.story.description} />
                    <meta property="og:url" content={`https://beheard.no/livshistorier/${this.state.story.slug}`} />

                    <meta name="twitter:title" content={this.state.story.title} />
                    <meta name="twitter:description" content={this.state.story.description} />

                    {this.state.story.featuredImage &&
                        <meta property="og:image" content={"https://beheard.no/" + this.state.story.featuredImage?.formats?.medium?.url} />}
                    {this.state.story.featuredImage &&
                        <meta name="twitter:image" content={"https://beheard.no/" + this.state.story.featuredImage?.formats?.medium?.url} />
                    }
                </Helmet>
                <div className={storyBackgroundClass} style={articleStyle} />
                <StoryHeading story={this.state.story} />

                <div className={contentClass} ref={this.dynamicContent}>
                    <ContentIndex toggleIndex={this.toggleIndex} currentSection={this.state.currentSection} open={open} content={this.state.story.content} />
                    <DynamicContent content={this.state.story.content} />
                </div>

                <PageMenuButton show={mediaQuery.isHandheld() && !this.state.isTop} onToggle={this.toggleIndex} icon={<FontAwesomeIcon icon={this.state.indexOpen ? faTimes : faList} color={styles.menuBlack} />} />



            </article>



        );

    }
}
