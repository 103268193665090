import React from 'react'
import ReactMarkdown from 'react-markdown';

export default class QuoteSection extends React.Component {
  constructor() {
    super();
    this.contentRef = React.createRef();

  }

  componentDidMount() {
  }

  render() {
    let textLines = [];
    let line = 0;
    this.props.text && this.props.text.split(' ').forEach((element,index) => {
      if (index % 14 === 0) {
        line++;
      } 
      if (textLines[line] === undefined) {
        textLines[line] = element;
      } else {
        textLines[line] += ` ${element}`;
      }
    });

    return (
      <section className="quote-section">
        <img src={this.props.icon?.url} />
        <div>
          {textLines.map((line, index) => <ReactMarkdown key={index} source={line} />)}
        </div>

      </section>
    );
  }
}
