import React from 'react';
import Overlay from '../../layout/overlay';
import Loading from '../../layout/loading';
import ImageThumbs from './image-thumbs';
import anime from 'animejs';

export default class ImageLightbox extends React.Component {
    constructor() {
        super();
        this.state = { loading: true, currentImage: null };
        this.currentImage = React.createRef();
    }

    componentDidMount() {
        if (this.props.currentImage !== undefined) {
            this.setState({ currentImage: this.props.currentImage });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.showImage !== null && prevProps.showImage !== this.props.showImage) {
            this.setState({ loading: true });
            anime({
                targets: this.currentImage.current,
                opacity: 0,
                duration: 300,
                easing: 'linear'
            });
            this.currentImage.current.addEventListener('load', () => {
                this.setState({ loading: false });
                anime({
                    targets: this.currentImage.current,
                    opacity: 1,
                    duration: 300,
                    easing: 'linear'
                });
            });
        }

    }

    renderContent() {
        let thumbStyle = {
            cursor: 'pointer'
        }
        return (<div className="lightbox">
            <div className="image-container">
                {this.state.loading && <Loading />}
                <img ref={this.currentImage} src={this.props.showImage.url} alt={this.currentImage.url} />
            </div>
            <div className="lightbox-thumbs">
                <ImageThumbs thumbStyle={thumbStyle} onClick={this.props.thumbClick} images={this.props.images} />
            </div>
        </div>);
    }

    render() {
        if (this.props.showImage === null) {
            return null;
        }

        let className = '';
        if (this.props.className !== undefined) {
            className += ' ' + this.props.className;
        }
        return (
            <Overlay className={className} onClick={this.props.close}>
                {this.renderContent()}
            </Overlay>
        );
    }
}
