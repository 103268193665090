import React from 'react'

export default class CtaButton extends React.Component {


  componentDidMount(){
  
  }

    render() {
        let target = this.props.content.newTab ? '_blank' :  '_self';
        
        return <a href={this.props.content.link} target={target} className="cta-button">{this.props.content.label}</a>
  }
}
