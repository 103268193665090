import React from 'react';
import * as moment from 'moment';
import Loading from './layout/loading';
import SectionHeading from './layout/section-heading';
import DynamicContent from './dynamic-content';
import Section from './layout/section';
import SiteLogo from './layout/site-logo';
import SingleImage from './components/single-image';
import PageHeading from './layout/page-heading';
import { getArticles } from './helpers/api';
import Slider from './components/slider/slider';
import EventCardBox from './components/event-card-box';
import { Helmet } from 'react-helmet';

export default class Event extends React.Component {
    constructor() {
        super();
        this.state = { loading: true, articles: [] };
        this.fetchEvent = this.fetchEvent.bind(this);
        this.urlPrefix = process.env.NODE_ENV === 'development' ? '' : 'https://beheard.no/api'

    }

    componentDidMount() {
        this.fetchEvent();
        this.getOtherAricles();

    }

    async getOtherAricles() {
        let articles = await getArticles('events');
        this.setState({ articles })
    }

    async fetchEvent() {
        const { slug } = this.props.match.params
        let response = await fetch(`${this.urlPrefix}/events?slug=${slug}`);
        let event = await response.json();

        if (Array.isArray(event)) {
            event = event[0];
        }
        if (event === undefined) {
            return;
        }

        event.publishedOn = moment(event.publishedOn)

        this.setState({ event, loading: false });
    }


    toggleIndex() {
        this.setState({ indexOpen: !this.state.indexOpen })
    }
    render() {
        if (this.state.loading) {
            return <Loading />
        }

        if (this.state.event === undefined) {
            return <h2>Could not find that event</h2>
        }


        let slides = this.state.articles.filter(article => article._id !== this.state.event._id).slice(0, 3).map(article => <EventCardBox key={article._id} {...article} />)
        return (
            <div className="content event-page">
                <Helmet>
                    <title>{this.state.event.title}</title>
                    <link rel="canonical" href={`https://beheard.no/kalender/${this.state.event.slug}`} />


                    <meta property="og:title" content={this.state.event.title} />
                    <meta property="og:description" content={this.state.event.description} />
                    <meta property="og:url" content={`https://beheard.no/kalender/${this.state.event.slug}`} />

                    <meta name="twitter:title" content={this.state.event.title} />
                    <meta name="twitter:description" content={this.state.event.description} />

                    {this.state.event.featuredImage &&
                        <meta property="og:image" content={"https://beheard.no/" + this.state.event.featuredImage.formats.medium.url} />}
                    {this.state.event.featuredImage &&
                        <meta name="twitter:image" content={"https://beheard.no/" + this.state.event.featuredImage.formats.medium.url} />
                    }
                </Helmet>
                <SiteLogo />

                <section className="event-intro">
                    <div className="container">
                        <PageHeading>{this.state.event.title}</PageHeading>
                    </div>
                </section>
                <article className="article event-article">

                    {this.state.event.featuredVideo ? <Section className="featured-video">
                        <div dangerouslySetInnerHTML={{ __html: this.state.event.featuredVideo }}></div>
                    </Section> : <Section>
                        <SingleImage {...this.state.event.featuredImage}></SingleImage>
                    </Section>}


                    <section className="dynamic-content event-content">
                        <DynamicContent content={this.state.event.content} />
                    </section>

                </article>

                <Section className="slider-section events-slider other-events">
                    <SectionHeading>Upcoming Events</SectionHeading>
                    {this.state.articles ? <Slider slides={slides} /> : <Loading />}
                </Section>

            </div>
        );

    }
}
