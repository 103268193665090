import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Home from './home';
import Stories from './stories';
import About from './about';
import NavMenu from './menu/nav-menu';
import './styles/styles.scss';
import styles from './helpers/styles';
import arkivetLogo from './images/arkivet-logo.png';
import Kontakt from './kontakt';
import Article from './article';
import Team from './team';
import Story from './story';
import OnlineExhibition from './online-exhibition';
import Event from './event';
import Events from './events';
import Project from './project';
import Projects from './projects';
import News from './news';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import Videos from './videos';
import ComingSoon from './coming-soon';
import Video from './video';


export default class Main extends React.Component {
    constructor() {
        super();
        this.state = { menuColor: styles.redText }
        this.setMenuColor = this.setMenuColor.bind(this);
    }

    // componentDidMount() {
    //     history.listen((location) => {

    //         window.ga('set', 'page', location.pathname + location.search);
    //         window.ga('send', 'pageview');
    //     });
    // }
    setMenuColor(color) {
        this.setState({ menuColor: color });
    }

    render() {

        return (
            <Router>
                <div className="main">
                    <NavMenu setMenuColor={this.setMenuColor} color={this.state.menuColor}></NavMenu>

                    <Switch>
                        <Route path="/om" component={About} />


                        <Route path="/aktuelt/:slug" component={Article} />
                        <Route path="/aktuelt" component={News} />


                        <Route exact={true} path="/livshistorier/" component={Stories} />
                        <Route path="/livshistorier/:slug" component={Story} />

                        <Route exact={true} path="/kalender/" component={Events} />
                        <Route path="/kalender/:slug" component={Event} />

                        <Route path="/kontakt" component={Kontakt} />
                        <Route path="/team" component={Team} />

                        <Route exact={true} path="/prosjekter/" component={Projects} />
                        <Route path="/prosjekter/:slug" component={Project} />

                        <Route path="/exhibition" component={OnlineExhibition} />
                        <Route exact={true} path="/video-fortellinger" component={Videos} />
                        <Route path="/video-fortellinger/:slug" component={Video} />
                        <Route path="/skin-remembers" component={() => {
                            window.location.href = 'https://beheard.no/skin-remembers';
                            return null;
                        }} >
                        </Route>

                        <Route path="/nalens-makt" component={() => {
                            window.location.href = 'https://beheard.no/nalens-makt';
                            return null;
                        }} >
                        </Route>

                        <Route path="/">
                            <Home setMenuColor={this.setMenuColor} />
                        </Route>
                    </Switch>
                </div>
                <footer>
                    <h3>BE HEARD!</h3>

                    <div className="container">
                        <div className="left">
                            <a href="https://arkivet.no" target="_blank" rel="noopener noreferrer" >
                                <img src={arkivetLogo} alt="Arkivets main logo" />
                            </a>
                            <address>
                                <span>Vesterveien 4, </span>
                                <span>4613 Kristiansand</span>
                                <br />
                                <span>Norway</span>
                            </address>
                        </div>

                        <div className="social-links">
                            <a href="https://www.facebook.com/Arkivetkrs" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebook} /></a>
                            <a href="https://www.instagram.com/arkivetkrs/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                            <a href="https://twitter.com/arkivetkrs?lang=enalso" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                            <a href="https://www.linkedin.com/company/arkivet-freds-og-menneskerettighetssenter" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a>
                            <a href="https://arkivet.no/ " rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faGlobe} /></a>

                        </div>
                    </div>

                </footer>
            </Router>
        );
    }
}

ReactDOM.render(
    <React.StrictMode>
        <Main />
    </React.StrictMode>,
    document.querySelector('#root'));