import React from 'react';
import Loading from './layout/loading';
import * as moment from 'moment';
import DynamicContent from './dynamic-content';
import Section from './layout/section';
import SectionHeading from './layout/section-heading';
import PageHeading from './layout/page-heading';
import SiteLogo from './layout/site-logo';
import styles from './helpers/styles';
import Slider from './components/slider/slider';
import { getArticles } from './helpers/api';
import SingleImage from './components/single-image';
import NewsCardBox from './components/news-card-box';
import { Helmet } from 'react-helmet';

export function resolveImageUrl(img, resolution) {
    if (!img || !img.formats) {
        return img[resolution]?.url || img.medium?.url || img.small?.url
    }
    return img.formats.m
}

export default class Article extends React.Component {
    constructor() {
        super();
        this.state = { loading: true };
        this.fetchArticle = this.fetchArticle.bind(this);
        this.urlPrefix = process.env.NODE_ENV === 'development' ? '' : 'https://beheard.no/api'

    }

    componentDidMount() {
        this.fetchArticle();
        this.getOtherArticles();

    }



    async getOtherArticles() {
        let articles = await getArticles('articles');
        this.setState({ articles })
    }

    async fetchArticle() {
        const { slug } = this.props.match.params
        let response = await fetch(`${this.urlPrefix}/articles?slug=${slug}`);
        let results = await response.json();

        let article = results[0];
        if (article === undefined) {
            return;
        }

        article.publishedOn = moment(article.publishedOn)
        this.setState({ article, loading: false });
    }

    render() {
        if (this.state.loading) {
            return <Loading />
        }

        if (this.state.article === undefined) {
            return <h2>Could not find that article</h2>
        }
        let slides = this.state.articles?.filter(article => article._id !== this.state.article._id).slice(0, 3).map(article => <NewsCardBox key={article._id} {...article} />)

        let style = { backgroundColor: styles.purpleBackground };
        return (
            <div className="content" style={style}>
                <Helmet>
                    <title>{this.state.article.title}</title>
                    <link rel="canonical" href={`https://beheard.no/aktuelt/${this.state.article.slug}`} />

                    <meta property="og:title" content={this.state.article.title} />
                    <meta property="og:description" content={this.state.article.description} />
                    <meta property="og:url" content={`https://beheard.no/aktuelt/${this.state.article.slug}`} />

                    <meta name="twitter:title" content={this.state.article.title} />
                    <meta name="twitter:description" content={this.state.article.description} />

                    {this.state.article.featuredImage &&
                        <meta property="og:image" content={"https://beheard.no/" + resolveImageUrl(this.state.article.featuredImage)} />}
                    {this.state.article.featuredImage &&
                        <meta name="twitter:image" content={"https://beheard.no/" + resolveImageUrl(this.state.article.featuredImage)} />
                    }
                </Helmet>
                <SiteLogo />

                <Section className="page-intro">
                    <div className="container">
                        <PageHeading>{this.state.article.title}</PageHeading>
                    </div>
                </Section>

                <Section>
                    <SingleImage {...this.state.article.featuredImage}></SingleImage>
                </Section>

                <article className="article news">
                    <Section className="dynamic-content article-content">
                        <DynamicContent content={this.state.article.content} />
                    </Section>
                </article>

                <Section className="more-articles">
                    <SectionHeading>MER AKTUELT</SectionHeading>
                    {this.state.articles ? <Slider slides={slides} /> : <Loading />}

                </Section>
            </div>
        );

    }
}
