import React from 'react'
import altImage from '../images/apostrophy-fill-curved.svg'
export default class StoryCardSmall extends React.Component {
 
    render() {
       
        let featuredImage = this.props.featuredImage ?  `${this.props.featuredImage?.url}` : altImage;
        let imageStyle = { backgroundImage: `url(${featuredImage})`, backgroundSize:  this.props.featuredImage ?  'cover' : 'contain'};

        return (<a href={`/livshistorier/${this.props.slug}`} className="card story-card-small">
            <div className="card-image-container story-image-container" style={imageStyle}>
            </div>
            <div className="story-title">
                <span>{this.props.title?.toUpperCase()}</span>
            </div>
    </a>);
  }
}
