
    class MSMediaQuery {
        constructor() {
            this.windowResizing = false;
            this.resizeTimeout = null;
            this.currentSize = this.isMobile() ? 'mobile' : this.isDesktop() ? 'desktop' : 'tablet';
            this.previousSize = null;
            this.onReSize = this.onReSize.bind(this);
            this.onReSize();
            window.addEventListener('resize', this.onReSize);
            this.resizeEvent = new Event('media-query-resize');
        }

        isDesktop() {
            return window.matchMedia('(min-width: 1201px)').matches;
        }

        isMobile() {
            return window.matchMedia('(max-width: 600px)').matches;
        }

        isTablet() {
            return window.matchMedia('(min-width: 601) and (max-width: 1200px)').matches;
        }

        isHandheld() {
            return window.matchMedia('(max-width: 1200px)').matches;
        }

        onReSize(e) {
            if (!this.windowResizing) {
                this.windowResizing = true;
            }

            if (this.resizeTimeout === null) {
                this.resizeTimeout = setTimeout(() => {
                    this.previousSize = this.currentSize;
                    this.currentSize = this.isMobile() ? 'mobile' : this.isDesktop() ? 'desktop' : 'tablet';
                    this.windowResizing = false;
                    this.resizeTimeout = null;
                    window.dispatchEvent(this.resizeEvent);

                }, 500);
            }

        }
    }
    
    export default new MSMediaQuery();