import React from 'react'
import Section from './layout/section';
import PageHeading from './layout/page-heading';
import SiteLogo from './layout/site-logo';
import styles from './helpers/styles';
import EventsList from './components/events-list';
import { createCategories, extractSearch, getArticles } from './helpers/api';
import SubMenuItem from './menu/sub-menu-item';
import Loading from './layout/loading';
import mediaQuery from './helpers/media-query';
import dayjs from 'dayjs';
import GenericPanel from './components/generic-panel';
import SidePanelService from './menu/side-panel-service';
import PageMenuButton from './layout/page-menu-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

export default class Events extends React.Component {
    constructor() {
        super();
        this.state = { articles: [], showPageMenu: false, filter: (article) => true, lastScrollPos: window.scrollY }
    }



    componentDidUpdate(prevProps, prevState) {
        let { category } = extractSearch(this.props.location.search);
        if (prevState.category !== category) {
            let filter;
            if (category === undefined) {
                filter = () => true;
            }
            else {
                filter = (article => article.categories.length === 0 || article.categories.some(cat => cat.name.toLowerCase() === category));
            }
            this.setState({ category, filter });
        }
    }

    async componentDidMount() {
        let articles = await getArticles('events');
        //let articles = [...req, ...req,...req,...req];
        let categories = createCategories(articles);
        this.setState({
            articles: articles.sort((a, b) => {
                return dayjs(b.start).unix() - dayjs(a.start).unix()
            }), categories
        })
        // setTimeout(() => { this.setState({ showPageMenu: true }) }, 500);
    }

    toggleMenu() {

        let categories = this.state.categories?.map(cat => <SubMenuItem handleClick={() => SidePanelService.closeSidePanel()} isActive={() => this.state.category === cat.name.toLowerCase()} key={cat._id} label={cat.name} link={`?category=${cat.name?.toLowerCase()}`} />) || []
        let menuItems = [
            <SubMenuItem handleClick={() => SidePanelService.closeSidePanel()} isActive={() => this.state.category === undefined} key="all-events" label="Alle" link={this.props.location.pathname} />,
            ...categories
        ]

        const menu = <GenericPanel title="Event Filters" className="event-filters page-menu">
            <ul>
                {menuItems}
            </ul>
        </GenericPanel>

        SidePanelService.setSidePanel(menu, { backgroundColor: styles.greenBackground }, 'event-filters');
    }

    render() {
        let filtered = this.state.articles?.filter(this.state.filter);

        let style = { backgroundColor: styles.greenBackground };
        return (
            <div style={style} className="content calender-page">
                <Helmet>
                    <title>Kalender</title>
                    <link rel="canonical" href={`https://beheard.no/kalender`} />


                    <meta property="og:title" content="Be Heard - Kalender" />
                    <meta property="og:url" content={`https://beheard.no/kalender`} />

                    <meta name="twitter:title" content="Be Heard - Kalender" />

                </Helmet>
                <Section className="calender-content">
                    <div style={{ paddingRight: this.state.showPageMenu && !mediaQuery.isMobile() ? '14rem' : '0', transition: 'all .3s ease' }}>
                        <SiteLogo />
                        <PageHeading>KALENDER</PageHeading>
                        {this.state.articles ? <EventsList articles={filtered} /> : <Loading />}
                    </div>
                    <PageMenuButton show={this.state.showPageMenu} onToggle={this.toggleMenu.bind(this)} icon={<FontAwesomeIcon icon={faFilter} color={styles.menuBlack} />} />


                </Section>


            </div>
        );
    }
}

//<PageMenu isOpen={this.state.showPageMenu}>{menuItems}</PageMenu>
