import React from 'react'
import ReactMarkdown from 'react-markdown';
import { ReactComponent as DoubleQuoteBackground } from '../images/double-quote-background.svg';
export default class QuoteLage extends React.Component {
  constructor() {
    super();
    this.contentRef = React.createRef();

  }

  componentDidMount() {
  }

  render() {
    return (
      <section className="large-quote-section" >
        <div className="large-quote-background" style={{ backgroundColor: this.props.backgroundColor || 'transparent' }} />
        <DoubleQuoteBackground stroke="black" className="dq-background" />
        <div className="rich-text" style={{ color: this.props.color || 'white' }}>
          <ReactMarkdown source={this.props.textContent} />
        </div>
      </section>
    );
  }
}
