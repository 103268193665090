import React from 'react'
import EventCard from './event-card';

export default class EventsList extends React.Component {


  componentDidMount(){
  }

  render() {
    return (<div className="events-list container">
      {this.props.articles.map(article => <EventCard key={article._id} {...article} />)}
    </div>);
  }
}
