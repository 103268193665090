import React from 'react'
import StoriesContainer from './components/stories-container';
import SiteLogo from './layout/site-logo';
import PageHeading from './layout/page-heading';
import Section from './layout/section';
import { createCategories, extractSearch, getArticles } from './helpers/api';
import Loading from './layout/loading';
import SubMenuItem from './menu/sub-menu-item';
import PageMenuButton from './layout/page-menu-button';
import styles from './helpers/styles';
import GenericPanel from './components/generic-panel';
import SidePanelService from './menu/side-panel-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

export default class Stories extends React.Component {

    constructor() {
        super();
        this.state = { articles: [], showPageMenu: false, filter: (article) => true }
    }

    componentDidUpdate(prevProps, prevState) {
        let { category } = extractSearch(this.props.location.search);
        if (prevState.category !== category) {
            let filter;
            if (category === undefined) {
                filter = () => true;
            }
            else {
                filter = (article => article.categories.length === 0 || article.categories.some(cat => cat.name.toLowerCase() === category));
            }
            this.setState({ category, filter });
        }
    }

    async componentDidMount() {
        let articles = await getArticles('stories?_sort=createdAt:desc');
        let categories = createCategories(articles);
        this.setState({ articles, categories })
        //setTimeout(() => { this.setState({ showPageMenu: true }) }, 500);
    }

    toggleMenu() {

        let categories = this.state.categories?.map(cat => <SubMenuItem handleClick={() => SidePanelService.closeSidePanel()} isActive={() => this.state.category === cat.name.toLowerCase()} key={cat._id} label={cat.name} link={`?category=${cat.name?.toLowerCase()}`} />) || []
        let menuItems = [
            <SubMenuItem handleClick={() => SidePanelService.closeSidePanel()} isActive={() => this.state.category === undefined} key="all-stories" label="Alle" link={this.props.location.pathname} />,
            ...categories
        ]
        const menu = <GenericPanel title="Story Filters" className="story-filters page-menu">
            <ul>
                {menuItems}
            </ul>
        </GenericPanel>

        SidePanelService.setSidePanel(menu, { backgroundColor: styles.darkBrown, color: styles.whiteText }, 'news-filters');
    }

    render() {

        let filtered = this.state.articles?.filter(this.state.filter);

        return (
            <div className="content stories-page">
                <Helmet>
                    <title>LIVSHISTORIER</title>
                    <link rel="canonical" href={`https://beheard.no/livshistorier`} />

                    <meta property="og:title" content="Be Heard - Livshistorier" />
                    <meta property="og:url" content={`https://beheard.no/livshistorier`} />

                    <meta name="twitter:title" content="Be Heard - Livshistorier" />

                </Helmet>
                <Section className="stories-content">
                    <div style={{ transition: 'all .3s ease' }}>
                        <SiteLogo />
                        <PageHeading>LIVSHISTORIER</PageHeading>
                        {this.state.articles ? <StoriesContainer stories={filtered} /> : <Loading />}
                    </div>

                    <PageMenuButton show={this.state.showPageMenu} onToggle={this.toggleMenu.bind(this)} icon={<FontAwesomeIcon icon={faFilter} color={styles.menuBlack} />} />

                </Section>

                {/*<Section className="slider-section">
                    { this.state.articles ? <StoriesSlider articles={this.state.articles.slice(0, 3)} /> : <Loading /> }
        </Section>*/}
            </div>
        );
    }
}
