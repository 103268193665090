import React from 'react'
import anime from 'animejs/lib/anime.es.js';
import ScrollSpy from '../helpers/scroll-spy';

export default class SectionHeading extends React.Component {
  constructor() {
    super();
    this.heading = React.createRef();
  }

  componentDidMount(){
    ScrollSpy.trackNode(this.heading.current, ()=>{
      return anime({
        targets: this.heading.current,
        duration: 600,
        delay: 0,
        opacity:[0,1],
        scaleY: [0,1],
        easing: 'easeInOutQuad'
      }).finished;
    }, 'in-view', () => { return anime({
      targets: this.heading.current,
      duration: 600,
      delay: 0,
      opacity:[1,0],
      scaleY: [1,0],
      easing: 'easeInOutQuad'
    }).finished
  }, 50);
    
  }


    render() {
      let content = this.props.link ?
        <a ref={this.heading} href={this.props.link}>{this.props.children}</a>: <span ref={this.heading}> {this.props.children} </span>;
      return <div className="section-heading">{content}</div>;
  }
}
