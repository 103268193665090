import React from 'react';
import dance from '../images/dance.png';
import ImageGrained from './images/image-grained';
import dayjs from 'dayjs';

export default class ArticleCardDated extends React.Component {
  
    render() {
        let imageSrc = this.props.featuredImage ? this.props.featuredImage.url : dance
        let link = `/aktuelt/${this.props.slug}`;
        let start = dayjs(this.props.createdAt);
        
        return (<a href={link} className="dated-article-card">
            <div className="image-date-container">
                <ImageGrained style={{backgroundImage: `url(${imageSrc})`}} className="img-container" />
                {start &&
                    <div className="article-date">
                        <span className="day">{start.format('DD')}</span>
                        <span className="month">{start.format('MM')}</span>
                        <span className="divider"></span>
                        <span className="year-one">{start.year().toString().slice(0, 2)}</span>
                        <span className="year-two">{start.year().toString().slice(2 - 4)}</span>
                    </div>
                }
            </div>
            <div className="article-info">
                <h2>{this.props.title}</h2>
                <div className="readable-date"><span className="day">{start?.format('DD MMMM YYYY')}</span></div>
                <p>{this.props.description}</p>
            </div>
       
        </a>);
    }
}
