import React from 'react'
import anime from 'animejs/lib/anime.es.js';

export default class SiteHeading extends React.Component {

  componentDidMount(){
    let heading = document.querySelector('.site-heading');
    anime({
      targets: heading,
      duration: 1000,
      //delay: 100,
      //opacity:[0,1],
      scale: [10,1],
        easing: 'easeOutBounce',
        complete: () => {
            setTimeout(() => {
            heading.classList.add('shadow');
            }, 300);
        }
    });
  }

    render() {
        let className = 'site-heading';
        //if (this.props.shadow) {
        //    className += ' shadow';
        //}
        return (<h1 className={className}>
        {this.props.children}
        <span>{this.props.children}</span>
    </h1>);
  }
}
