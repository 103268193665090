import React from 'react'
import SectionHeading from '../layout/section-heading';
import { getArticles } from '../helpers/api';
import Loading from '../layout/loading';
import StoryCardSmall from './story-card-small';

export default class StoriesPanel extends React.Component {
    constructor() {
        super();
        this.state = { opacity: 0, articles:[] };
    }

    async getData() {
        let articles = await getArticles('stories');
        this.setState({ articles });
    }
    componentDidMount() {
        this.getData();
        setTimeout(() => this.setState({ opacity: 1 }), 500);
    }

    renderArticles() {
        return this.state.articles.map(article => <StoryCardSmall key={article._id} {...article} />);
    }

    render() {
      

        return (
            <div className="stories">
                <SectionHeading link="/livshistorier">LIVSHISTORIER</SectionHeading>
                <div className="stories-container">
                    {this.state.articles.length > 0 ? this.renderArticles() : <Loading />}
                </div>
            </div>
        );
  }
}
