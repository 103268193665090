import React from 'react';

export default class OnlineExhibition extends React.Component {


    render() {
    
        return (
            <article>
                <section className="page-intro">
                    <div className="container">
                        <h1 className="page-heading">Coming Soon</h1>
                    </div>
                </section>

            </article>

        );

    }
  }
