import React from 'react'
import Section from './layout/section'
import SectionHeading from './layout/section-heading'
import anime from 'animejs/lib/anime.es.js';
import ScrollSpy from './helpers/scroll-spy';
import styles from './helpers/styles';
import EventsContainer from './components/events-container';
import "regenerator-runtime";
import StoriesSlider, { getStoryQuote } from './components/stories-slider';
import SiteIntro from './layout/site-intro';
import { getArticles } from './helpers/api';
import VideoStory from './components/video-story';
import NewsCard from './components/news-card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import mediaQuery from './helpers/media-query';
import dayjs from 'dayjs';

import nalensMakt from './images/makt-2.png'
import skin from './images/skin-2.png'

export default class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            stories: [],
            news: [],
            events: [],
            videoStories: [],
            showPrevVideo: false,
            showNextVideo: true
            /* Events: HocEvents(EventsContainer, EventCardBordered, (events) => { return events }),
            News: HocNews(NewsContainer, NewsCard, (news) => { return news }),
            Stories: HocStories(StoriesSlider, (stories) => stories, true) */
        };
        this.videosSection = React.createRef();
    }

    async getData() {
        let stories = await getArticles('stories?_sort=createdAt:desc');
        let news = await getArticles('articles?_sort=createdAt:desc&_limit=3');


        let videoStories = await getArticles('video-stories');
        this.setState({ stories, news, videoStories: videoStories.sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix()).slice(0, 7) });

    }

    componentDidMount() {

        this.getData();

        let topSection = document.querySelector('.site-intro');
        if (topSection !== null) {
            ScrollSpy.trackNode(topSection, () => {
                this.props.setMenuColor(styles.redText);
            }, 'in-view', null, topSection.getBoundingClientRect().height / 2);

        }

        let storiesSection = document.querySelector('.home-stories');
        if (storiesSection !== null) {
            ScrollSpy.trackNode(storiesSection, () => {
                this.props.setMenuColor(styles.lightBrown);
            }, 'in-view', null, storiesSection.getBoundingClientRect().height / 2);

        }
        let videoStoriesSection = document.querySelector('.home-video-stories');
        if (videoStoriesSection !== null) {
            ScrollSpy.trackNode(videoStoriesSection, () => {
                this.props.setMenuColor(styles.pinkBackground);
            }, 'in-view', () => {

            }, videoStoriesSection.getBoundingClientRect().height / 2);

        }

        let calenderSection = document.querySelector('.home-calender');
        if (calenderSection !== null) {
            ScrollSpy.trackNode(calenderSection, () => {
                this.props.setMenuColor(styles.greenBackground);
            }, 'in-view', null, calenderSection.getBoundingClientRect().height / 2);

        }
        let newsSection = document.querySelector('.home-news');
        if (newsSection !== null) {
            ScrollSpy.trackNode(newsSection, () => {
                this.props.setMenuColor(styles.purpleBackground);
            }, 'in-view', null, newsSection.getBoundingClientRect().height / 2);

        }

        //this.onScroll();
    }

    onScroll() {
        let iconsTop = document.querySelector('.icons-top');
        let iconsBottom = document.querySelector('.icons-bottom');
        window.addEventListener('scroll', (e) => {

            if (iconsTop !== null) {
                const firstTop = iconsTop.getBoundingClientRect().top;

                let top = iconsTop.getBoundingClientRect().top;
                let adjust = top - firstTop;
                if (adjust < -150) {
                    anime({
                        targets: iconsTop,
                        translateX: `${adjust}px`,
                        duration: 1,
                        easing: 'linear'
                    });
                }

                if (iconsBottom !== null) {
                    const firstbottom = iconsBottom.getBoundingClientRect().top;

                    let bottom = iconsBottom.getBoundingClientRect().top;
                    let bottomAdjust = firstbottom - bottom;
                    if (bottomAdjust > 150) {
                        anime({
                            targets: iconsBottom,
                            translateX: `${bottomAdjust}px`,
                            duration: 1,
                            easing: 'linear'
                        });
                    }
                }
            }

        })


    }

    onWheel(e) {
        e.stopPropagation();
        if (!this.videosSection.current) {
            return;
        }
        if (e.deltaY > 0) this.videosSection.current.scrollLeft += 100;
        else this.videosSection.current.scrollLeft -= 100;
    }
    scrollRight() {
        if (!this.videosSection.current) {
            return;
        }
        else this.videosSection.current.scrollLeft += window.innerWidth / (mediaQuery.isMobile() ? 1.5 : 3);

    }

    scrollLeft() {
        if (!this.videosSection.current) {
            return;
        }
        else this.videosSection.current.scrollLeft -= window.innerWidth / (mediaQuery.isMobile() ? 1.5 : 3);

    }

    onVideoScroll() {
        if (this.videosSection?.current?.scrollLeft === 0) {
            this.setState({ showPrevVideo: false });
        } else {
            this.setState({ showPrevVideo: true })
        }

        if (this.videosSection?.current?.scrollLeft >= this.videosSection?.current?.scrollWidth - this.videosSection?.current?.clientWidth) {
            this.setState({ showNextVideo: false });
        } else {
            this.setState({ showNextVideo: true })
        }
    }

    render() {
        let newsSlides = this.state.news.map(article => <NewsCard key={article._id} {...article} />);
        return (
            <div className="content">
                <div className="home-content">
                    <SiteIntro />
                    <Section className="home-video-stories">
                        <SectionHeading link="/video-fortellinger">VIDEO FORTELLINGER</SectionHeading>
                        <div key="home-v-s" ref={this.videosSection} className="container video-stories-container" onScroll={this.onVideoScroll.bind(this)} >

                            {this.state.videoStories.map((story, index) => {
                                // let mask = getStoryQuoteUrl(this.state.videoStories.length - index);
                                let backgroundQuote = getStoryQuote(index);
                                return (<VideoStory key={'story-' + story._id} backgroundQuote={backgroundQuote} {...story} useSlug={true} />)
                            })}


                        </div>
                        <div className="hoz-scroll-controlls">
                            {this.state.showPrevVideo && <div className="prev" onClick={this.scrollLeft.bind(this)}><FontAwesomeIcon icon={faChevronLeft} /></div>}
                            {this.state.showNextVideo && <div className="next" onClick={this.scrollRight.bind(this)}><FontAwesomeIcon icon={faChevronRight} /></div>}
                        </div>
                    </Section>
                    <Section className="home-stories">
                        <SectionHeading link="/livshistorier">LIVSHISTORIER</SectionHeading>
                        <StoriesSlider articles={this.state.stories} />
                    </Section>
                    <Section className="home-calender">
                        <SectionHeading>NETTUTSTILLINGER</SectionHeading>
                        <div className="events-container">
                            <a href="https://beheard.no/nalens-makt" target="_blank" className="event-card-bordered">
                                <div className="img-container">
                                    <picture>
                                        <img src={nalensMakt} />
                                    </picture>
                                </div>
                                <div className="event-info">
                                    <h3>NÅLENS MAKT</h3>
                                    <p>Dagens kvinner broderer fortellinger om identitet, motstand og kulturarv</p>
                                    {/* <p>Prosjektet Nålens makt startet i januar 2019 med en serie av broderings workshops over 6 uker. Workshopene brakte sammen kvinner med flyktning- og migrasjonsbakgrunn og kvinner fra lokalsamfunnet. Kvinnene fikk i oppgave å brodere i fellesskap et eget motiv som skulle uttrykke noe om sin identitet og kulturarv. Denne nettutstillingen er satt sammen av alle disse individuelle broderiene. «Nålens makt» handler om å styrke kvinners egen bevissthet, gjeninnføre brodering som uttrykksform og vise betydningen det kan ha.</p> */}

                                </div>
                            </a>
                            <a href="https://beheard.no/skin-remembers" target="_blank" className="event-card-bordered">
                                <div className="img-container">
                                    <picture>
                                        <img src={skin} />
                                    </picture>
                                </div>
                                <div className="event-info">
                                    <h3>SKIN REMEMBERS</h3>
                                    <p>Individer med minoritetsbakgrunn fra Irak, Palestina, Syria og Sàpmi forteller om tanker og følelser knyttet til sin identitet.</p>
                                    {/* <p>Denne utstillingen presenterer en samling minoritetshistorier og sammenvevde identiteter som kommer til uttrykk gjennom et mangfold av kreative uttrykk. Gjennom film, foto, animasjon, virtuell virkelighet, dans, musikk og poesi møter vi en variert gruppe mennesker og blir bedre kjent med deres bakgrunn og deres unike identiteter.</p> */}

                                </div>
                            </a>

                        </div>
                    </Section>
                    <Section className="home-news">
                        <SectionHeading link="/aktuelt">AKTUELT</SectionHeading>
                        <div className="news-container">
                            {newsSlides}
                        </div>
                    </Section>


                </div>
            </div>
        );
    }
}
