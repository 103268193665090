import React from 'react';
import anime from 'animejs/lib/anime.es.js';
import Container from './container';
import TextBox from './text-box';
import { ReactComponent as altIcon1 } from '../images/logo-icons/logo-icon (1).svg';
import { ReactComponent as altIcon2 } from '../images/logo-icons/logo-icon (2).svg';
import { ReactComponent as altIcon3 } from '../images/logo-icons/logo-icon (3).svg';
import { ReactComponent as altIcon4 } from '../images/logo-icons/logo-icon (4).svg';
import { ReactComponent as altIcon5 } from '../images/logo-icons/logo-icon (5).svg';
import { ReactComponent as altIcon6 } from '../images/logo-icons/logo-icon (6).svg';
import { ReactComponent as altIcon7 } from '../images/logo-icons/logo-icon (7).svg';
import { ReactComponent as altIcon8 } from '../images/logo-icons/logo-icon (8).svg';
import { ReactComponent as altIcon9 } from '../images/logo-icons/logo-icon (9).svg';
import { ReactComponent as altIcon10 } from '../images/logo-icons/logo-icon (10).svg';
import { ReactComponent as altIcon11 } from '../images/logo-icons/logo-icon (11).svg';
import { ReactComponent as altIcon12 } from '../images/logo-icons/logo-icon (12).svg';
import { ReactComponent as altIcon13 } from '../images/logo-icons/logo-icon (13).svg';
import { ReactComponent as altIcon14 } from '../images/logo-icons/logo-icon (14).svg';
import fistOrg from '../images/Fist.svg';
import fistBrown from '../images/Fist_brown.svg';
import fistWhite from '../images/Fist_white.svg';
import fistBlack from '../images/Fist_black.svg';
import Section from './section';
import SiteHeading from './site-heading';
import mediaQuery from '../helpers/media-query';

export default class SiteIntro extends React.Component {
    constructor() {
        super();
        this.upperIcons = [altIcon3, altIcon2, altIcon1, altIcon4, altIcon5, altIcon6, altIcon7];
        this.lowerIcons = [altIcon8, altIcon9, altIcon10, altIcon11, altIcon12, altIcon13, altIcon14];
        this.state = { currentIcon: 0, scale: 1, animationProgress: 0, currentFist: 0 };
        this.nextIcon = this.nextIcon.bind(this);
        this.setScale = this.setScale.bind(this);
        this.container = React.createRef();
    }

    componentDidMount() {
        let svgs = document.querySelectorAll('.home-icons svg');
        if (svgs !== null) {
            [...svgs].forEach(svg => {
                let gs = svg.querySelectorAll('g');
                let ps = svg.querySelectorAll('path');
                let cs = svg.querySelectorAll('circle');

                let paths = [...gs, ...ps, ...cs];
                anime({
                    targets: paths,
                    strokeDashoffset: [anime.setDashoffset, 0],
                    easing: 'easeInOutSine',
                    duration: 600,
                    delay: 2000,
                    complete: () => {
                        this.setState({ animationProgress: 1 });
                    }
                })

            });

        }

        let logo = document.querySelector('.arkivet-logo');
        anime({
            targets: logo,
            delay: 2000,
            opacity: [0, 1],
            easing: 'linear',

        })

        window.addEventListener('scroll', this.nextIcon);
        window.addEventListener('scroll', this.setScale);

        this.fistTimeout = setInterval(() => {
            this.setState((state) => ({ currentFist: state.currentFist === 3 ? 0 : state.currentFist++ }))
        }, 2000)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.nextIcon);
        window.removeEventListener('scroll', this.setScale);
        clearInterval(this.fistTimeout)
    }

    setScale() {
        let rect = this.container.current.getBoundingClientRect();
        let distance = rect.height + rect.top;
        if (distance > 0) {
            let scale = (distance) / rect.height;
            //if (scale > 1) {
            //    scale = 1;
            //}
            //if (scale < 0.5) {
            //    scale = 0.5;
            //}


            this.setState({ scale });
        }
    }

    nextIcon() {

        let nextIndex = Math.floor(window.scrollY / 80 % 7);
        this.setState({ currentIcon: nextIndex });
    }

    getFist() {
        let fists = [fistBrown, fistWhite, fistBlack, fistOrg];
        // return fists[(this.state.scale * 10).toFixed(0) % 3];
        return fists[this.state.currentFist]
    }

    render() {
        let min = 0;
        let scale = Math.max(Math.min(this.state.scale, 1), min);
        let fontSize = mediaQuery.isDesktop() ? 174 : 17;
        let secondIndex = this.state.currentIcon === 6 ? 0 : this.state.currentIcon + 1;
        let FirstIcon = this.upperIcons[this.state.currentIcon];
        let SecondIcon = this.upperIcons[secondIndex];
        let ThirdIcon = this.lowerIcons[this.state.currentIcon];
        let FourthIcon = this.lowerIcons[secondIndex];
        let height = (mediaQuery.isDesktop() ? 260 : 40) * Math.pow(scale, 2);
        let iconsBottomStyle = {
            transform: `translateX(${scale * scale * (mediaQuery.isDesktop() ? 260 : 10)}px)`
        }
        let iconsTopStyle = {
            transform: `translateX(${scale * scale * -(mediaQuery.isDesktop() ? 260 : 10)}px)`
        }
        let margin = mediaQuery.isDesktop() ? scale * 1 : scale * 0.5;
        let firstStyle = { strokeWidth: '12px', height: height + 'px', marginRight: `${margin}rem` };
        let secondStyle = { strokeWidth: '12px', height: height + 'px', marginRight: `${margin}rem` };
        let containerStyle = {
            fontSize: fontSize * scale + (mediaQuery.isDesktop() ? 'px' : 'vw'),
            top: `${scale * (mediaQuery.isDesktop() ? 4 : 2.5)}rem`
        }
        let textStyle = {
            opacity: Math.pow(this.state.scale, 4),
            transform: `translateX(${(1 - scale) * -1000}px)`
        };

        let fist = this.getFist();

        let sectionStyle = { backgroundImage: 'url(' + fist + ')' }

        return (<Section passedRef={this.container} className="site-intro home" style={sectionStyle} >
            <Container style={containerStyle}>
                <div className="home-icons icons-top" style={iconsTopStyle}>
                    <FirstIcon style={firstStyle} />
                    <SecondIcon style={secondStyle} />
                </div>

                <SiteHeading shadow={this.state.animationProgress === 1}>BE HEARD!</SiteHeading>

                <TextBox style={textStyle} from="left">
                    <p className="intro-text">Historiefortelling er et kraftfullt redskap som kan bygge broer mellom mennesker og utvide v&aring;r forst&aring;else av verden.</p>
                </TextBox>

                <div className="home-icons icons-bottom" style={iconsBottomStyle}>
                    <ThirdIcon style={firstStyle} />
                    <FourthIcon style={secondStyle} />
                </div>

            </Container>
        </Section>);
    }
}

//              <img className="arkivet-logo" src={arkivetLogo} alt="Logo for Arkivet"/>
