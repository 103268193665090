import React from 'react';
import GenericPanel from '../components/generic-panel';
import mediaQuery from '../helpers/media-query';
import styles from '../helpers/styles';
import SidePanelService from './side-panel-service';
import SubMenuItem from './sub-menu-item';

export default class MainMenu extends React.Component {
    constructor() {
        super();
        this.state = { aboutPages: [] };
        this.urlPrefix = process.env.NODE_ENV === 'development' ? '' : 'https://beheard.no/api'

    }
    componentDidMount() {
        this.getContent();
    }

    async getContent() {
        let response = await fetch(`${this.urlPrefix}/about`);
        let about = await response.json();
        this.setState({
            aboutPages: about.pages.map(page => <SubMenuItem handleClick={() => SidePanelService.closeSidePanel()} key={page._id} label={page.title} link={`/om/${page.slug}`} />),
        });
    }

    openAboutPanel() {
        const menu = <GenericPanel title="Om Be Heard" className="om-pages page-menu">
            <ul>
                {this.state.aboutPages}
            </ul>
        </GenericPanel>

        SidePanelService.setSidePanel(menu, { backgroundColor: styles.lightGreen, width: mediaQuery.isMobile() ? 400 : 300, transition: 'width .3s ease' }, 'om-pages');
    }

    render() {
        return (
            <ul className="main-menu">
                <SubMenuItem handleClick={this.props.closeMenu} label="Hjem" link="/" />
                <SubMenuItem handleClick={this.props.closeMenu} label="Kalender" link="/kalender" />
                <SubMenuItem handleClick={this.props.closeMenu} label="Video fortellinger" link="/video-fortellinger" />
                <SubMenuItem handleClick={this.props.closeMenu} label="Kontakt" link="/kontakt" />
                <li style={{ cursor: 'pointer' }} onClick={() => { this.openAboutPanel() }} className="sub-menu-item" ref={this.menuItemRef}>
                    Om
                </li>
                <SubMenuItem handleClick={this.props.closeMenu} label="Livshistorier" link="/livshistorier" />
                <SubMenuItem handleClick={this.props.closeMenu} label="Aktuelt" link="/aktuelt" />
                <li className="sub-menu-item" ><a href="https://beheard.no/skin-remembers">Online Exhibition</a></li>


                {mediaQuery.isMobile() && <SubMenuItem handleClick={this.props.searchClick} label="Søk" link="/search" />}
            </ul>
        )
    }
}
// <SubMenuItem handleClick={this.props.closeMenu} label="Nyhet" link="/articles" />
//<SubMenuItem handleClick={this.props.closeMenu} label="Stories" link="/stories" />
/*<SubMenuItem handleClick={this.props.closeMenu} label="Visjon/Form&aring;l" link="/om/visjon" />
 *

                <SubMenuItem handleClick={this.props.closeMenu} label="Historisk kontekst" link="/om/historisk-kontekst" />
                <SubMenuItem handleClick={this.props.closeMenu} label="Faglig kontekst" link="/om/faglig-kontekst" />*/