import React from 'react';
import * as moment from 'moment';
import Loading from './layout/loading';
import SectionHeading from './layout/section-heading';
import DynamicContent from './dynamic-content';
import Section from './layout/section';
import SiteLogo from './layout/site-logo';
import SingleImage from './components/single-image';
import PageHeading from './layout/page-heading';
import { getArticles } from './helpers/api';
import Slider from './components/slider/slider';
import { Helmet } from 'react-helmet';
import VideoStory from './components/video-story';
import dayjs from 'dayjs';

export default class Video extends React.Component {
    constructor() {
        super();
        this.state = { loading: true, articles: [] };
        this.fetchVideo = this.fetchVideo.bind(this);
        this.urlPrefix = process.env.NODE_ENV === 'development' ? '' : 'https://beheard.no/api'

    }

    componentDidMount() {
        this.fetchVideo();
        this.getOtherAricles();

    }

    async getOtherAricles() {
        let articles = await getArticles('video-stories');
        this.setState({ articles: articles.sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix()) })
    }

    async fetchVideo() {
        const { slug } = this.props.match.params
        let response = await fetch(`${this.urlPrefix}/video-stories?slug=${slug}`);
        let video = await response.json();

        if (Array.isArray(video)) {
            video = video[0];
        }
        if (video === undefined) {
            return;
        }

        video.publishedOn = moment(video.publishedOn)

        this.setState({ video, loading: false });
    }


    toggleIndex() {
        this.setState({ indexOpen: !this.state.indexOpen })
    }
    render() {
        if (this.state.loading) {
            return <Loading />
        }

        if (this.state.video === undefined) {
            return <h2>Could not find that video</h2>
        }


        let slides = this.state.articles.filter(article => article._id !== this.state.video._id).map(article => <VideoStory key={article._id} {...article} useSlug={true} />)
        slides = slides.slice(0, 3)
        slides.push(<div className="view-all-slide"><a href="/video-fortellinger">Se alle</a></div>);
        return (
            <div className="content video-page">
                <Helmet>
                    <title>{this.state.video.title}</title>
                    <link rel="canonical" href={`https://beheard.no/kalender/${this.state.video.slug}`} />


                    <meta property="og:title" content={this.state.video.title} />
                    <meta property="og:description" content={this.state.video.description} />
                    <meta property="og:url" content={`https://beheard.no/kalender/${this.state.video.slug}`} />

                    <meta name="twitter:title" content={this.state.video.title} />
                    <meta name="twitter:description" content={this.state.video.description} />

                    {this.state.video.featuredImage &&
                        <meta property="og:image" content={"https://beheard.no/" + this.state.video.featuredImage.formats.medium.url} />}
                    {this.state.video.featuredImage &&
                        <meta name="twitter:image" content={"https://beheard.no/" + this.state.video.featuredImage.formats.medium.url} />
                    }
                    {this.state.video.directVideoLink &&
                        <meta property="og:video" content={this.state.video.directVideoLink} />
                    }

                </Helmet>
                <SiteLogo />

                <section className="video-intro">
                    <div className="container">
                        <PageHeading>{this.state.video.title}</PageHeading>
                    </div>
                </section>
                <article className="article video-article">
                    <VideoStory key={this.state.video._id} {...this.state.video} />
                </article>

                <Section className="slider-section videos-slider other-videos">
                    <SectionHeading>Andre videofortellinger</SectionHeading>
                    {this.state.articles ? <Slider slides={slides} /> : <Loading />}
                </Section>

            </div>
        );

    }
}
