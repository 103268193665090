export default {
    menuBlack: '#464545',
    red: '#FD5252',
    redText: '#E07373',
    yellowBackground:'#DDD7CD',
    pinkBackground:'#FF9797',
    greenBackground:'#B2DEAC',
    purpleBackground: '#B2A5DE',
    blackBackground: '#464545',
    turquoise: '#91b7b3',
    frantzYellow: '#fce373',
    redBackground: '#FF9797',
    lightBrown: '#8D7A71',
    darkBrown: '#483B34',
    lightGreen:'#6D8377',
    darkText: '#464545',
    skinWhite: '#e4cda6',
    skinBrown: '#af8258',
    skinBlack: '#6e513f',
    whiteBackground: '#E8EAE9'
}

