import React from 'react'
import SectionHeading from '../layout/section-heading';

export default class GenericPanel extends React.Component {
    constructor() {
        super();
        this.state = { opacity: 0};
    }

   
    componentDidMount() {
        setTimeout(() => this.setState({ opacity: 1 }), 500);
    }

  
    render() {
      

        return (
                <div className={this.props.className + ' side-panel-container'} style={{ opacity: this.state.opacity }}>
                    <SectionHeading link={this.props.titleLink}>{this.props.title}</SectionHeading>
                    
                    {this.props.children}
                </div>
        );
  }
}
