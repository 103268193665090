import React from 'react'
import Container from '../layout/container';
import ArticleCard from './article-card';

export default class TeamContainer extends React.Component {
 
    componentDidMount() {
    }

    renderTeamMembers() {
        if (this.props.articles === undefined || this.props.articles.length === 0) {
            return <p>Couldn't find any team members</p>
        }

        return this.props.articles.map(teamMember => <ArticleCard key={teamMember._id} {...teamMember} title={teamMember.name} />);
    }

    render() {
        return (
            
            <Container className="team-members">
                {this.renderTeamMembers()}
            </Container>
      );
    }
  }
