import React from 'react'
import SectionHeading from '../layout/section-heading';
import { getArticles } from '../helpers/api';
import Loading from '../layout/loading';
import VideoStory from './video-story';
import dayjs from 'dayjs'

export default class VideoStoriesPanel extends React.Component {
    constructor() {
        super();
        this.state = { opacity: 0, articles: [] };
    }

    async getData() {
        let articles = await getArticles('video-stories');
        this.setState({ articles });
    }
    componentDidMount() {
        this.getData();
        setTimeout(() => this.setState({ opacity: 1 }), 500);
    }

    renderArticles() {
        return this.state.articles.sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix()).map(article => <VideoStory key={article._id} {...article} />);
    }

    render() {


        return (
            <div className="stories video-stories">
                <SectionHeading link="/video-fortellinger">VIDEO FORTELLINGER</SectionHeading>
                <div className="stories-container">
                    {this.state.articles.length > 0 ? this.renderArticles() : <Loading />}
                </div>
            </div>
        );
    }
}
