import React from 'react';
import CommaFill from '../images/comma-fill-sharp.svg'
import ImageGrained from './images/image-grained';
import dayjs from 'dayjs';

export default class NewsCard extends React.Component {
    constructor() {
        super();
        this.state = { grained: false };
        this.mouseEnter = this.mouseEnter.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);
    }

    mouseEnter() {
        this.setState({ grained: true })
    }

    mouseLeave() {
        this.setState({ grained: false })

    }

    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    render() {
        let imageSrc = this.props.featuredImage ? this.props.featuredImage.url : CommaFill;
        //let id = this.props.featuredImage ? this.props.featuredImage._id : this.makeid();
        let created = dayjs(this.props.createdAt);
        return (
            <a key={this.props._id} href={'/aktuelt/' + this.props.slug} className="news-card" onMouseEnter={this.mouseEnter}
                onMouseLeave={this.mouseLeave}>

                <ImageGrained src={imageSrc} className="img-container" />
                <div className="news-card-info">
                    <h2>{this.props.title}</h2>
                    <div className="article-date"><span className="day">{created.format('DD.MM.YYYY')}</span></div>
                    <h3>{this.props.description}</h3>
                    <h1>{this.props.category}</h1>
                    <span className="read-more">Les mer...</span>
                </div>
            </a>);
    }
}
