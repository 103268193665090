import React from 'react'
import anime from 'animejs/lib/anime.es.js';

export default class TextBox extends React.Component {
  constructor() {
    super();
    this.contentRef = React.createRef();

  }

  componentDidMount(){
    let from = this.props.from === 'left' ? ['-100%', '0%'] : ['100%', '0%'];
    anime({
      targets: this.contentRef.current,
      duration: 600,
      delay: 1000,
      opacity:[0,1],
      translateX: from,
      easing: 'easeInOutQuad'
    });
  }

  render() {
      return (<div style={this.props.style} className="text-box" ref={this.contentRef}>
      {this.props.children}
    </div>);
  }
}
