import React from 'react';
import * as ReactMarkdown from 'react-markdown';
import SectionHeading from './layout/section-heading';
import ImageGallery from './components/images/image-gallery';
import TextWithImage from './layout/text-with-image';
import CtaButton from './components/cta-button';
import SingleImage from './components/single-image';
import QuoteSection from './layout/quote-section';
import QuoteSmall from './layout/quote-small';
import QuoteLage from './layout/quote-large';

export default class DynamicContent extends React.Component {

    componentDidMount() {
    }


    render() {
        return this.props.content.map((item, index) => {
            switch (item.__component) {
                case 'content.text':
                    return <section key={item._id} className="indexable article-content" id={`section-${item._id}`} >
                        {(item.preTitle || item.sectionTitle) && <SectionHeading>
                            {item.preTitle && <i>{item.preTitle} </i>}
                            <h1>{item.sectionTitle?.toUpperCase()}</h1>
                        </SectionHeading>}
                        <ReactMarkdown source={item.ContentBody} /></section>
                case 'content.gallery':
                    return <ImageGallery key={item._id} images={item.images} useLightbox={item.useLightbox} />
                case 'content.text-and-image':
                    return <TextWithImage key={item._id} content={item} useLightbox={item.useLightbox} />
                case 'content.call-to-action-button':
                    return <CtaButton key={item._id} content={item} />
                case 'content.image':
                    return <SingleImage key={item._id} {...item.image} />
                case 'content.quote':
                    return <QuoteSection key={item._id} {...item} />
                case 'content.small-quote':
                    return <QuoteSmall key={item._id} {...item} />
                case 'content.large-quote':
                    return <QuoteLage key={item._id} {...item} />

                case 'content.outro':
                    return <section key={item._id} className="outro" ><div ><h1>{item.title}</h1><ReactMarkdown source={item.textContent} /></div></section>
                case 'content.boxed-text':
                    return <div key={item._id} className="boxed-text">
                        <h3>{item.title}</h3>

                        <ReactMarkdown source={item.textContent} />
                    </div>
                case 'content.highlighted-text':
                    return <div key={item._id} className="highlighted-text">
                        <ReactMarkdown source={item.textContent} />
                    </div>
                case 'content.vimeo':
                    return <div className="vimeo-embed" dangerouslySetInnerHTML={{ __html: item.embedCode }}></div>
                default: return null;
            }
        });

    }
}
