
import React from 'react';
import  SidePanelService  from '../menu/side-panel-service';


export default class PageMenuButton extends React.Component{
    constructor(props) {
        super(props);
        this.state = { show: props.show };
    }

    componentDidUpdate() {
        if (this.props.show !== this.state.show) {
            this.setState({show: this.props.show})
        }
    }
    componentDidMount() {
        SidePanelService.monitor({
            in: () => {
                this.setState({ show: false });
            },
            out: () => {
                this.setState({ show: true });
            }
        })
}
    toggle() {
        if (typeof this.props.onToggle === 'function') {
            this.props.onToggle();
        }
    }
    render() {
        if (!this.state.show) {
            return null;
        }
        return(
            <div className="page-menu-button" onClick={this.toggle.bind(this)}>
                {this.props.icon}
            </div>
        )
    }
}