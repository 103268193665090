import React from 'react'
import Quote from '../layout/quote';
import altImage from '../images/Fist.svg'
import ImageMasked from './images/image-masked';
import CategoryButton from './category-button';
import ArticleLink from './article-link';

export default class StoryCard extends React.Component {

    categoryClick(e, category) {
        e.stopPropagation();
    }

    renderTags() {
        return this.props?.categories?.map(cat => <CategoryButton onClick={(e) => this.categoryClick(e, cat)} key={cat._id} category={cat} />);
    }

    render() {
        let featuredImage = this.props.quoteImage ? this.props.quoteImage.url : this.props.featuredImage ? `${this.props.featuredImage?.url}` : altImage;
        let className = "card story-card-horizontal "

        if (this.props.orientation) {
            className += this.props.orientation;
        }

        return (<a href={`/livshistorier/${this.props.slug}`} className={className}>
            {this.props.backgroundQuote}
            <ImageMasked className="card-image-container story-image-container" mask={this.props.quoteSvg} src={featuredImage} />
            <div className="story-info">

                <Quote title={this.props?.title}>{this.props.quote}</Quote>


                <ArticleLink label="Les min historie"></ArticleLink>
            </div>
        </a>);
    }
}

