import React from 'react';
import dance from '../images/dance.png';
import ImageGrained from './images/image-grained';

export default class ArticleCard extends React.Component {
  
    render() {
        let imageSrc = this.props.featuredImage ? this.props.featuredImage.url : dance
        let link = `${this.props.slug}`;
        
        return (<a href={link} className="article-card">
            <div className="image-container">
                <ImageGrained style={{backgroundImage: `url(${imageSrc})`}} className="img-container" />
         
            </div>
            <div className="article-info">
                <h2>{this.props.title}</h2>
                <p>{this.props.description}</p>
            </div>
       
        </a>);
    }
}
