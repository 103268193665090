import React from 'react';

export default class CategoryButton extends React.Component {

    render() {
      
        return (
            <button className="category-button">{this.props.category.name}</button>
        );

    }
  }
