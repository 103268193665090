import React from 'react';
import Section from './layout/section';
import PageHeading from './layout/page-heading';
import SiteLogo from './layout/site-logo';
import styles from './helpers/styles';
import { createCategories, extractSearch, getArticles } from './helpers/api';
import SubMenuItem from './menu/sub-menu-item';
import Loading from './layout/loading';
import NewsContainer from './components/news-container';
import PageMenuButton from './layout/page-menu-button';
import GenericPanel from './components/generic-panel';
import SidePanelService from './menu/side-panel-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
export default class News extends React.Component {
    constructor() {
        super();
        this.state = { articles: [], showPageMenu: false, filter: () => true }
    }

    componentDidUpdate(prevProps, prevState) {
        let { category } = extractSearch(this.props.location.search);
        if (prevState.category !== category) {
            let filter;
            if (category === undefined) {
                filter = () => true;
            }
            else {
                filter = (article) => article.categories.length > 0 && article.categories.some(cat => cat.name.toLowerCase() === category);
            }
            this.setState({ category, filter });
        }
    }

    async componentDidMount() {
        let articles = await getArticles('articles');
        let categories = createCategories(articles);
        this.setState({ articles, categories });


        //setTimeout(() => { this.setState({ showPageMenu: true }) }, 500);
    }

    toggleMenu() {

        let categories = this.state.categories?.map(cat => <SubMenuItem handleClick={() => SidePanelService.closeSidePanel()} isActive={() => this.state.category === cat.name.toLowerCase()} key={cat._id} label={cat.name} link={`?category=${cat.name?.toLowerCase()}`} />) || []

        let menuItems = [
            <SubMenuItem handleClick={() => SidePanelService.closeSidePanel()} isActive={() => this.state.category === undefined} key="all-news" label="Alle" link={this.props.location.pathname} />,
            ...categories
        ]
        const menu = <GenericPanel title="Aktuelt Filters" className="news-filters page-menu">
            <ul>
                {menuItems}
            </ul>
        </GenericPanel>

        SidePanelService.setSidePanel(menu, { backgroundColor: styles.purpleBackground }, 'news-filters');
    }


    render() {
        let filtered = this.state.articles?.filter(this.state.filter).sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix());

        let style = { backgroundColor: styles.purpleBackground };
        return (
            <div style={style} className="content news-page">
                <Helmet>
                    <title>Aktuelt</title>
                    <link rel="canonical" href={`https://beheard.no/aktuelt`} />

                    <meta property="og:title" content="Aktuelt" />
                    <meta property="og:url" content={`https://beheard.no/aktuelt`} />

                    <meta name="twitter:title" content="Aktuelt" />
                </Helmet>
                <Section className="news-content">
                    <div style={{ transition: 'all .3s ease' }}>
                        <SiteLogo />
                        <PageHeading>AKTUELT</PageHeading>
                        {filtered ? <NewsContainer articles={filtered} /> : <Loading />}
                    </div>
                    <PageMenuButton show={this.state.showPageMenu} onToggle={this.toggleMenu.bind(this)} icon={<FontAwesomeIcon icon={faFilter} color={styles.menuBlack} />} />

                </Section>


            </div>
        );
    }
}