import React from 'react'
import Section from './layout/section';
import PageHeading from './layout/page-heading';
import SiteLogo from './layout/site-logo';
import styles from './helpers/styles';
import PageMenu from './layout/page-menu';
import { createCategories, extractSearch, getArticles } from './helpers/api';
import SubMenuItem from './menu/sub-menu-item';
import Loading from './layout/loading';
import ProjectsContainer from './components/projects-container';


export default class Projects extends React.Component {


    constructor() {
        super();
        this.state = { articles: [], showPageMenu: false, filter: (article) => true }
    }

    componentDidUpdate(prevProps, prevState) {
        let { category } = extractSearch(this.props.location.search);
        if (prevState.category !== category) {
            let filter;
            if (category === undefined) {
                filter = () => true;
            }
            else {
                filter = (article => article.categories.length === 0 || article.categories.some(cat => cat.name.toLowerCase() === category));
            }
            this.setState({ category, filter });
        }
    }

    async componentDidMount() {
        let articles = await getArticles('activities');
        let categories = createCategories(articles);
        this.setState({ articles, categories })
        setTimeout(() => { this.setState({ showPageMenu: true }) }, 500);
    }

    render() {
        let filtered = this.state.articles?.filter(this.state.filter);
        let categories = this.state.categories?.map(cat => <SubMenuItem isActive={() => this.state.category === cat.name.toLowerCase()} key={cat._id} label={cat.name} link={`?category=${cat.name?.toLowerCase()}`} />) || []
        let menuItems = [
            <SubMenuItem isActive={() => this.state.category === undefined} key="all-news" label="Alle" link={this.props.location.pathname} />,
            ...categories
        ]

        let style = { backgroundColor: styles.purpleBackground };
        return (
            <div style={style} className="content projects-page">

                <Section className="projects-content">
                    <div style={{ paddingRight: this.state.showPageMenu ? '14rem' : '0', transition: 'all .3s ease' }}>
                        <SiteLogo />
                        <PageHeading>PROSJEKTER</PageHeading>
                        {this.state.articles ? <ProjectsContainer articles={filtered} /> : <Loading />}
                    </div>

                    <PageMenu isOpen={this.state.showPageMenu}>{menuItems}</PageMenu>

                </Section>


            </div>


        );
    }
}
