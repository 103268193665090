import React from 'react';
import SiteLogo from './layout/site-logo';
import Section from './layout/section';
import PageHeading from './layout/page-heading';
import styles from './helpers/styles';
import { createCategories, extractSearch, getArticles } from './helpers/api';
import Loading from './layout/loading';
import TeamContainer from './components/team-container';
import { Helmet } from 'react-helmet';

export default class Team extends React.Component {

    constructor() {
        super();
        this.state = { articles: [], filter: (article) => true }
    }

    componentDidUpdate(prevProps, prevState) {
        let { category } = extractSearch(this.props.location.search);
        if (prevState.category !== category) {
            let filter;
            if (category === undefined) {
                filter = () => true;
            }
            else {
                filter = (article => article.categories.length === 0 || article.categories.some(cat => cat.name.toLowerCase() === category));
            }
            this.setState({ category, filter });
        }
    }

    async componentDidMount() {
        let articles = await getArticles('team-members');
        let categories = createCategories(articles);
        this.setState({ articles, categories })
    }

    render() {
        let filtered = this.state.articles?.filter(this.state.filter);

        let style = { backgroundColor: styles.purpleBackground };
        return (
            <div style={style} className="content team-page">
                <Helmet>
                    <title>Team</title>
                    <link rel="canonical" href={`https://beheard.no/om/team`} />

                </Helmet>
                <Section className="team-content">
                    <div style={{ paddingRight: this.state.showPageMenu ? '14rem' : '0', transition: 'all .3s ease' }}>
                        <SiteLogo />
                        <PageHeading>TEAM</PageHeading>
                    </div>

                </Section>

                <Section className="team-section">
                    {this.state.articles ? <TeamContainer articles={filtered} /> : <Loading />}
                </Section>


            </div>


        );
    }
}
