import React from 'react'
import Container from './layout/container';
import Section from './layout/section';
import PageHeading from './layout/page-heading';
import SiteLogo from './layout/site-logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEdit, faEnvelope, faExclamation, faMapMarkerAlt, faMobileAlt, faPaperPlane, faUser } from '@fortawesome/free-solid-svg-icons';
import styles from './helpers/styles';
import { ReactComponent as ArkivetLogo } from './images/arkivet-logo.svg';
import Loading from './layout/loading';
import { Helmet } from 'react-helmet';

export default class Kontakt extends React.Component {

    constructor() {
        super();
        this.state = {
            name: {
                value: "",
                validator: (value) => {
                    this.setState({ error: false })

                    return !value && 'Name is empty';
                }
            },
            email: {
                value: "",
                validator: (value) => {
                    this.setState({ error: false })
                    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                    return !re.test(value) && 'Email is invalid';
                }
            },
            mobile: {
                value: "",
                validator: (value) => {
                    this.setState({ error: false })

                    let phoneFormat = /^[0-9]{0,}$/;
                    return !phoneFormat.test(value) && 'Mobile is invalid';
                }
            },
            message: {
                value: "",
                validator: (value) => {
                    this.setState({ error: false })

                    return !value && 'Message is empty';
                }
            }, loading: false
        }
        this.onChange = this.onChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.urlPrefix = process.env.NODE_ENV === 'development' ? '' : 'https://beheard.no/api'
            ;

    }
    async submitForm(e) {
        e.preventDefault();
        this.setState({ loading: true });
        try {
            let { name, email, mobile, message } = this.state;
            let request = await fetch(this.urlPrefix + '/sendmail', {
                method: 'POST',
                body: JSON.stringify({ name: name.value, email: email.value, mobile: mobile.value, message: message.value })
            });


            let response = await request.json();

            if (request.status === 404 || response.status === 'error') {
                throw ("Could not send the form");
            }
            this.setState({ loading: false, response });
        } catch (error) {
            setTimeout(() => {
                this.setState({ loading: false, error });


            }, 2000)

        }

    }

    onChange(e) {
        let state = {
            ...this.state
        };

        state[e.target.name].value = e.target.value;
        state[e.target.name].error = this.state[e.target.name].validator(e.target.value);
        this.setState(state)
    }

    render() {
        let submitClass = this.state.loading ? 'sending' : 'ready';
        let disabled = this.state.loading || this.state.error;
        return (
            <div className="content contact-page">
                <SiteLogo />
                <Helmet>
                    <title>Kontakt</title>
                    <link rel="canonical" href={`https://beheard.no/kontakt`} />

                    <meta property="og:title" content="Be Heard - Kontakt" />
                    <meta property="og:url" content={`https://beheard.no/kontakt`} />

                    <meta name="twitter:title" content="Be Heard - Kontakt" />
                </Helmet>
                <Section className="page-intro contact">
                    <Container>
                        <PageHeading>KONTAKT</PageHeading>
                    </Container>
                </Section>
                <Section>
                    <Container>
                        <address className="address" >
                            <FontAwesomeIcon icon={faMapMarkerAlt} color={styles.redBackground} />
                            <ul className="address-lines">
                                <li>Archive Foundation</li>
                                <li>Vesterveien 4, 4616 Kristiansand</li>
                            </ul>
                        </address>
                    </Container>
                    <Container className="form-container">

                        <div className="map">
                            <iframe title="google-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5008.356937965937!2d7.981695862492316!3d58.14427751519183!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf6c8dd4a374b924f!2sArchive%20Foundation!5e0!3m2!1sen!2sno!4v1605774605333!5m2!1sen!2sno" width="450" height="350" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                            <div className="arkivet-logo"><ArkivetLogo /></div>
                        </div>

                        <div className="contact-form">
                            <form onSubmit={this.submitForm}>
                                <div className={"input-with-icon" + (this.state.name.error ? ' error' : '')}>
                                    <input type="text" name="name" placeholder="Navn" value={this.state.name.value} onChange={this.onChange} />
                                    <FontAwesomeIcon icon={faUser} color={styles.darkText} />
                                    <p className="error">{this.state.name.error}</p>
                                </div>
                                <div className={"input-with-icon" + (this.state.email.error ? ' error' : '')}>
                                    <input type="email" name="email" placeholder="Epost" value={this.state.email.value} onChange={this.onChange} />
                                    <FontAwesomeIcon icon={faEnvelope} color={styles.darkText} />
                                    <p className="error">{this.state.email.error}</p>

                                </div>
                                <div className={"input-with-icon" + (this.state.mobile.error ? ' error' : '')}>
                                    <input type="phone" name="mobile" placeholder="Mobilnummer" value={this.state.mobile.value} onChange={this.onChange} />
                                    <FontAwesomeIcon icon={faMobileAlt} color={styles.darkText} />
                                    <p className="error">{this.state.mobile.error}</p>

                                </div>
                                <div className={"input-with-icon text-area" + (this.state.message.error ? ' error' : '')}>
                                    <textarea name="message" placeholder="Melding" value={this.state.message.value} onChange={this.onChange} />
                                    <FontAwesomeIcon icon={faEdit} color={styles.darkText} />
                                    <p className="error">{this.state.message.error}</p>

                                </div>
                                {this.state.loading && <Loading />}
                                <div className="errors">
                                    {this.state.error && <p>{this.state.error}</p>}
                                </div>
                                <button disabled={disabled} className={submitClass} type="submit">Send
                                    {this.state.response ? <FontAwesomeIcon icon={faCheckCircle} /> :
                                        this.state.error ? <FontAwesomeIcon icon={faExclamation} /> : <FontAwesomeIcon icon={faPaperPlane} className="plane" />}
                                </button>
                            </form>
                        </div>
                    </Container>
                </Section>
            </div>


        );
    }
}
