import React from 'react'
import { Link } from 'react-router-dom';
import anime from 'animejs/lib/anime.es.js';
import MSMediaQuery from '../helpers/media-query';
import NavItemText from './nav-item-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import MediaQuery from '../helpers/media-query';

export default class NavItem extends React.Component {
  constructor() {
    super();
    this.menuItemRef = React.createRef();
    this.state = { showText: false };
  }

  componentDidMount() {
    if (MSMediaQuery.isDesktop()) {
      let svg = this.menuItemRef.current.querySelector('svg');
      if (svg !== null) {
        let gs = svg.querySelectorAll('g');
        let ps = svg.querySelectorAll('path');
        let cs = svg.querySelectorAll('circle');
        let ln = svg.querySelectorAll('line');

        let paths = [...gs, ...ps, ...cs, ...ln];
      this.menuItemRef.current.addEventListener('mouseenter', () => {
        let animation = {
          targets: paths,
          strokeDashoffset: [anime.setDashoffset, 0],
          easing: 'easeInOutSine',
          duration: 600,
        }
        if(this.props.fillColor !== undefined){
          animation.fill =  this.props.fillColor;

        }
            anime(animation)
        this.setState({ showText: true });
      });

      this.menuItemRef.current.addEventListener('mouseleave', () => {
        anime({
          targets: paths,
          easing: 'linear',
          duration: 600,
          fill: 'rgba(0,0,0,0)'
        })
            this.setState({ showText: false });
        });

      }

    
  }

  }


  render() {
    let text = <NavItemText show={this.state.showText}>{this.props.label}</NavItemText>;
    let className = 'nav-item';
    if(this.props.className){
      className += ` ${this.props.className}`;
    }

    if(this.props.active){
      className += ' active';
    }

    let content;
    if (this.props.link !== undefined) {
      content = (
        <Link to={this.props.link} target={this.props.target || "_self"}>
            {this.props.icon}

        </Link>
      );
    } else {
      content = this.props.icon
      
    }
    return (
      <li style={{stroke:this.props.color}} onClick={this.props.handleClick} className={className} ref={this.menuItemRef}>
        {content}
        {!MediaQuery.isMobile() &&  text}
        {!MediaQuery.isMobile() && <FontAwesomeIcon icon={faCaretRight} className="active-after" color={this.props.backgroundColor} />}
      </li>
    );
  }
}
